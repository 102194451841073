import FileHandle from '../global/file-handle';
import TableHandle from "../global/table-handle-2";
class PagePCEImportBulkTags extends HTMLElement {
  constructor(){
    super();
    this.type = 'page';
    
    this.tableWrapper = this.getElementsByClassName("table-wrapper")[0];
    this.progress = this.getElementsByClassName("progress")[0];
    this.progressBar = this.getElementsByClassName("progress-bar")[0];
    this.progressCount = this.getElementsByClassName("progress-count")[0];

    this.displayColumns = [
      {key: 'index', label: "Index"},
      {key: 'Handle', label: "Handle"},
      {key: 'Title', label: "Title"},
      {key: 'BodyHTML', label: "Short Content"},
      {key: 'TemplateSuffix', label: "Template"},
      {key: 'Metafield:title_tag', label: "SEO Title"},
      {key: 'Metafield:description_tag', label: "SEO Description"},
      {key: 'Metafield:product_tags', label: "Metafield"},
    ];
    
    this.token = document.head.querySelector('meta[name="csrf-token"]').content;

    this.btnImport = this.getElementsByClassName("btn-start-import")[0];
    
    this.btnImport.addEventListener("click", this.startImportPagesPCE.bind(this));
    this.isLoadFileContent = true;

    this.fileHandle = new FileHandle(this);

    this.tableHandle = new TableHandle(this.type, this.tableWrapper, null, "", this.displayColumns);
    
    
    this.seoTitleKey = "title_tag";
    this.seoDescKey = "description_tag";
    this.metaKey = "product_tags";
    
    this.addEventListener("prm:fileLoaded", this.onFileLoad.bind(this) );
  }
  
  onFileLoad(e){
    /* code on file load here */
    let data = e.detail.data;
    
    this.tableHandle.setData(data);
    this.tableHandle.generateTable();
  }

  startImportPagesPCE() {
    let toggle = this.getElementsByClassName("toggle");
    let indexHasChoose = [];
    for(let i = 0; i < toggle.length; i++) {
      if(toggle[i].checked) {
        let toggleValue = toggle[i].value;
        toggleValue = toggleValue.replace("index-","");
        indexHasChoose.push(parseFloat(toggleValue));
      }
    }
    let data = this.fileHandle.getData();
    this.pagesImport = [];
    for(let j in indexHasChoose) {
      let pageData = data[indexHasChoose[j]];
      pageData['index'] = indexHasChoose[j];
      this.pagesImport.push(pageData);
    }

    this.importInterval = null;
    this.totalPages = this.pagesImport.length;
    this.startedCount = 0;
    this.doneCount = 0;

    this.progressBar.ariaValueMax = this.totalPages;
    this.progressCount.innerText = "0/" + this.totalPages;

    this.progress.classList.remove("hide");

    this.importInterval = setInterval(this.importPagesToShopify.bind(this), 2200);
  }
    

  importPagesToShopify() {
    var content = this.pagesImport.shift();
    this.startedCount++;
    
    this.checkPageExistByHandle(content);
    
    if (this.pagesImport.length == 0) {
      clearInterval(this.importInterval);
      return;
    }
  }

  checkPageExistByHandle(content){
    // edit object item index based on columns of CSV file before import 
    let handle = content[3];
    let formData = new FormData();

    formData.append("_token", this.token);
    formData.append("handle", handle);
    
    var seoTitle = content[4];
    var seoDesc = content[5];
    var metaValue = content[6];

    var that = this;
    fetch("/page/info", {method: "POST", body: formData})
        .then(res => res.json())
        .then( (res) => {
          let page_id = res.id;
          
          if(page_id != undefined) {
            setTimeout(that.addSEOMeta(res.id, that.seoTitleKey, seoTitle), 500);
            setTimeout(that.addSEOMeta(res.id, that.seoDescKey, seoDesc), 1500);
            setTimeout(that.addCustomMetafield(res.id, that.metaKey, metaValue), 2000);
            console.log("Page: " + content[0] + " is exist, added SEO meta and custom metafield");
          } else {
            that.createPage(content);
          }
          that.countDone();
        })
        
  }

  createPage(content) {
    // edit object item index based on columns of CSV file before import 
    let handle = content[0];
    let title = content[1];
    let body = content[2];
    let template = content[3];
    let seoTitle = content[4];
    let seoDesc = content[5];
    let metaValue = content[6]
    let formData = new FormData();
    formData.append("title", title);
    formData.append("template_suffix", template);
    formData.append("body_content", body);
    formData.append("seo_handle", handle);
    formData.append("_token", this.token);

    var that = this;

    fetch("/pages/create", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      
      let page_id = result.page.id;
      if(page_id != '') {
        console.log('Page: ' + content[0] + '-' + page_id + ' does not exist, create page and add SEO meta - custom metafield');
        setTimeout(that.addSEOMeta(page_id, that.seoTitleKey, seoTitle), 500);
        setTimeout(that.addSEOMeta(page_id, that.seoDescKey, seoDesc), 1500);
        setTimeout(that.addCustomMetafield(page_id, that.metaKey, metaValue), 2000);

        this.tableHandle.setRowStatus(content.index, 'success');
      }
    });
  }
  addSEOMeta(pageID, key, value){
    let formData = new FormData();
    formData.append("id", pageID);
    formData.append("key", key);
    formData.append("value", value);
    formData.append("_token", this.token);
    formData.append("action", "add_seo_title");

    var that = this;

    fetch("/pages/add-seo", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      console.log(result);
    });
  }
  addCustomMetafield(pageID, key, value){
    let formData = new FormData();
    formData.append("id", pageID);
    formData.append("key", key);
    formData.append("value", value);
    formData.append("_token", this.token);
    formData.append("action", "add_metafield");

    var that = this;

    fetch("/pages/add-metafield", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      console.log(result);
    });
  }
  countDone(){
    this.doneCount++;

    let percentDone = parseFloat(this.doneCount * 100) / this.totalPages;
    
    this.progressCount.innerText = this.doneCount + "/" + this.totalPages;
    this.progressBar.ariaValueNow = this.doneCount;
    this.progressBar.style.width = percentDone + "%";

    if (this.doneCount == this.totalPages) {
      setTimeout(() => {
        this.progressBar.style.backgroundColor = "rgb(2, 222, 64)";
        this.progressCount.innerText = "DONE";
      }, 500);
      
    }
  }
  
}
customElements.define("page-pce-import", PagePCEImportBulkTags);