import Sortable from 'sortablejs';
class ESFieldToFormElement extends HTMLElement{
    constructor(){
        super();
        this.btnAddFormField = this.querySelector(".btn-add-field-to-form");
        this.btnAddFormField.addEventListener('click', this.showAddFormField.bind(this));

        this.accordionFieldToForm = this.querySelector('.accordion');
        
        this.formElement = document.querySelector("global-form form");
    }
    
    showAddFormField(e){
        this.formElement.classList.remove('was-validated');
        let numberField = this.btnAddFormField.getAttribute('data-field-number');
        let boxFormField = this.querySelector('.box-add-form-field');
        boxFormField.querySelectorAll('.form-check-validate').forEach(el => {
            el.setAttribute('name', 'field[validates]['+numberField+'][]');
        });
        boxFormField.querySelector('.title-field').innerHTML = 'Field '+numberField;

        let defaultESFormField = boxFormField.outerHTML;

        

        let itemAccordion = document.createElement('div');
        itemAccordion.className = 'accordion-item';
        
        let headerItemAccordion = document.createElement('h2');
        headerItemAccordion.className = 'accordion-header';
        headerItemAccordion.id = 'heading-'+numberField;

        let btnItemAccordion = document.createElement('button');
        btnItemAccordion.className = 'accordion-button collapsed';
        btnItemAccordion.setAttribute('type', 'button');
        btnItemAccordion.setAttribute('data-bs-toggle', 'collapse');
        btnItemAccordion.setAttribute('data-bs-target', '#collapse-'+numberField);
        btnItemAccordion.setAttribute('aria-expanded', 'true');
        btnItemAccordion.setAttribute('aria-controls', 'collapse-'+numberField);
        btnItemAccordion.innerHTML = 'Field #'+numberField;

        let handleBtn = document.createElement('i');
        handleBtn.className = 'fas fa-arrows-alt handle';

        btnItemAccordion.prepend(handleBtn);
        headerItemAccordion.append(btnItemAccordion);

        let bodyItemAccordion = document.createElement('div');
        bodyItemAccordion.className = 'accordion-collapse collapse show';
        bodyItemAccordion.id = 'collapse-'+numberField;    
        bodyItemAccordion.setAttribute('aria-labelledby', 'heading-'+numberField);
        bodyItemAccordion.setAttribute('data-bs-parent', '#'+this.accordionFieldToForm.id);

        let innerBodyItemAccordion = document.createElement('div');
        innerBodyItemAccordion.className = 'accordion-body';

        let esFormField = document.createElement('es-form-field');
        esFormField.className = 'item-field-'+numberField;
        esFormField.innerHTML = defaultESFormField;
        
        innerBodyItemAccordion.append(esFormField);
        bodyItemAccordion.append(innerBodyItemAccordion);

        itemAccordion.append(headerItemAccordion);
        itemAccordion.append(bodyItemAccordion);
        this.accordionFieldToForm.append(itemAccordion);
        //this.append(esFormField);
        this.btnAddFormField.setAttribute('data-field-number', Number(numberField) + 1);
        
        this.setAttrRequired(esFormField);
    }

    setAttrRequired(esFormField){
        console.log(esFormField);
        esFormField.querySelector('.form-field-label').setAttribute('required', 'required');
        //esFormField.querySelector('.form-field-name').setAttribute('required', 'required');
        esFormField.querySelector('.form-field-type').setAttribute('required', 'required');
        
    }
    
}
customElements.define("es-fields-to-form", ESFieldToFormElement);

const accordionCreateFieldToForm = document.getElementById('accordionFieldToForm');
if(accordionCreateFieldToForm){
    new Sortable(accordionCreateFieldToForm, {
        handle: '.handle',
        animation: 150,
        onEnd: function(){
            document.getElementById('accordionFieldToForm').querySelectorAll('.accordion-item').forEach((el, i) =>{
                let number = i+1;
                el.querySelectorAll('.form-check-validate').forEach((check) =>{
                    check.setAttribute('name', 'field[validates]['+ number +'][]');
                });
            });
        }
    });
}