import Dropzone from "dropzone";
import Papa from "papaparse";

/**
 * Nhiệm vụ: tải file và trả về data, có thể cho gọi callback sau khi tải file
 */
export default class FileHandle {
  constructor (element) {
    this.data;
    this.element = element;
    this.form = this.element.getElementsByClassName("dropzone")[0];
    this.type = element.type;

    // add attribute "autoupload" to custom elements to make dropzone autoupload
    let autoProcessQueue = typeof this.element.attributes.autoupload != "undefined" ? this.element.attributes.autoupload.value !== "false" : false; 

    this.uploadZone = new Dropzone(this.form, {
      autoProcessQueue: false,
      createImageThumbnails: false,
      addRemoveLinks: true,
      headers: {
        'X-CSRF-TOKEN': this.element.token
      },
      acceptedFiles: ".csv",
      autoProcessQueue: autoProcessQueue, 
      dictDefaultMessage: "Drop PCE "+ this.type +"s CSV file here to upload and start process"
    });

    if(typeof this.element.isLoadFileContent != "undefined" && this.element.isLoadFileContent){
      this.uploadZone.on("addedfile", this.onFileSelectCallback.bind(this));
    }

    if(autoProcessQueue){
      this.uploadZone.on("success", this.onFileUploadSuccessCallback.bind(this));
    }

  }

  setData(data) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  onFileUploadSuccessCallback(file, responseText, e){
    this.element.dispatchEvent(new CustomEvent("prm:fileUploaded", { detail:{ file: file, response: responseText } } ));
  }

  onFileSelectCallback(file) {

    if (typeof window.FileReader !== 'function')
      throw ("The file API isn't supported on this browser.");

    let fr = new FileReader();
    this.file = file;

    fr.onload = this.onLoadFileHandler.bind(this);

    fr.readAsText(file);

    return false;
  }

  onLoadFileHandler(file) {

    let csvContent = Papa.parse(file.currentTarget.result);

    this.data = csvContent.data.filter((item) => Array.isArray(item) && item.length > 1);

    this.recordsCount = this.data.length;

    this.element.dispatchEvent(new CustomEvent("prm:fileLoaded", { detail:{ data: this.data } } ));
  }
}