class GeoIPListElement extends HTMLTableElement{
  connectedCallback(){
   
    this.body = this.querySelector("tbody");
    this.head = this.querySelector("thead");
  }

  get size(){
    return this.body.childElementCount;
  }

  addRule(rule){
    let row = document.createElement("tr", { is: "geoip-rule-item" } )

    row.dataset.ruleId = rule.id;
    row.init(rule);

    this.body.append(row);
  }

  
  updateRule(rule){
    this.querySelector(".rule-" + rule.id).update(rule);
  }
}

customElements.define("geoip-list", GeoIPListElement, {extends: "table"});

window.geoIpList = document.querySelector("#geoip-list")
