class GeoIpRuleStatusTableRowElement extends HTMLTableRowElement{
  constructor(){
    super();

    this.iconChecked = '<i class="fa-solid fa-square-check fa-xl"></i>';
    this.iconUnchecked = '<i class="fa-regular fa-square fa-xl"></i>';
  }
  connectedCallback(){
    this.deleteUrl = document.querySelector("#delete-url").value;
    this.updateUrl = document.querySelector("#update-url").value;
    this.getUrl = document.querySelector("#get-url").value;


    this.token = document.head.querySelector('meta[name="csrf-token"]').content;

    this.querySelector(".remove").addEventListener("click", this.onDeleteCallback.bind(this));
    this.querySelector(".edit").addEventListener("click", this.onEditCallback.bind(this));
  }

  init(rule){
    this.classList.add("rule-" + rule.id);
    this.append(this.createHeadCell("", "index"));
    this.append(this.createCell(rule.title, "title"));
    this.append(this.createCell(rule.redirect_to, "redirect_to"));
    this.append(this.createCell(rule.countries, "countries"));

    this.append(this.createBoolCell(rule.preserve_relative, "preserve_relative"));
    this.append(this.createBoolCell(rule.status, "status"));
    this.append(this.createActionCell(rule.status, "actions"));

    this.querySelectorAll("a").forEach((e) => { e.dataset.ruleId = rule.id })
  }

  switchStatus(status){
    this.querySelector(".status").innerHTML = status ? this.iconChecked : this.iconUnchecked;
  }

  get formData(){
    let formData = new FormData();

    formData.append("_token", this.token);
    formData.append("id", this.dataset.ruleId);

    return formData;
  }

  onDeleteCallback(e){
    e.preventDefault();

    this.delete();

    return false;
  }

  onEditCallback(e){
    e.preventDefault();

    this.get();

    return false;
  }

  get(){
    fetch(this.getUrl, { method: "post", "body": this.formData })
      .then(res => res.json())
      .then(res => {
        document.querySelector("#geoip-form").fill(res);
      })
      .catch(e => console.error(e));
  }

  update(rule){
    this.querySelector(".title").textContent = rule.title;
    this.querySelector(".redirect_to").textContent = rule.redirect_to;
    this.querySelector(".countries").textContent = rule.countries;

    if(rule.preserve_relative){
      this.querySelector(".preserve_relative").innerHTML = this.iconChecked;
    }
    else{
      this.querySelector(".preserve_relative").innerHTML = this.iconUnchecked;
    }
    if(rule.status){
      this.querySelector(".status").innerHTML = this.iconChecked;
    }
    else{
      this.querySelector(".status").innerHTML = this.iconUnchecked;
    }
  }

  delete(){
    let that = this;
    
    fetch(this.deleteUrl, { method: 'post', body: this.formData })
      .then(res => res.json())
      .then(res => {
        if(res.rs)
          that.remove();
      })
  }

  setCellClass(cell, classes){
    if(typeof classes == 'object'){
      for(var i in classes){
        cell.classList.add(classes[i]);
      }
    }
    else if(typeof classes == 'string'){
      cell.classList.add(classes);
    }
  }
    
  createHeadCell(html, classes){
    let cell = document.createElement("th");
    
    this.setCellClass(cell, classes);

    cell.innerHTML = html;
    return cell;
  }

  /**
   * 
   * @param {string} html the inner html for cell
   * @param {Array} classes the list of class for cell, if you have 1 class only, you may pass it as a single string (without space)
   * @returns void
   */
  createCell(html, classes){
    let cell = document.createElement("td");

    this.setCellClass(cell, classes);

    cell.innerHTML = html;
    return cell;
  }

  createBoolCell(status, classes){
    return this.createCell(status ? this.iconChecked : this.iconUnchecked, classes );
  }

  createActionCell(status, classes){
    let html = '<div class="btn-toolbar"><div class="btn-group me-2"><a href="javascript: void(0);" class="btn btn-primary edit"><i class="fa-solid fa-edit"></i></a><a href="javascript: void(0);" class="btn btn-danger remove"><i class="fa-solid fa-remove"></i></a></div><div class="btn-group"><a href="javascript: void(0);" class="btn btn-primary btn-toggle btn-toggle--icon' + (status ? " active" : "" ) +  '" role="button" data-bs-toggle="button" title="Disabled">' + this.iconChecked + this.iconUnchecked + '</a></div></div>';

    return this.createCell(html, classes);
  }

  editCell(html, classes){
    
  }
  
}
customElements.define("geoip-rule-item", GeoIpRuleStatusTableRowElement, {extends: "tr"});