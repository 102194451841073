import Dropzone from "dropzone";
import Papa from "papaparse";

class ProductEdit extends HTMLElement{
    constructor(){
        super();
        this.id = this.querySelector("input[name='id']").value;
        this.form = this.querySelector("#edit-form");
        this.formVariants = this.querySelector("#product-detail-variants");
        this.btnSubmit = this.querySelector("#edit-submit");
        this.uploadImages = this.form.querySelector("#imageUpload");
        this.token = document.head.querySelector('meta[name="csrf-token"]').content;
        this.imagesDelete = [];
        this.variantsDelete = [];
        this.btnDeleteImg = this.getElementsByClassName("delete_image");
        
        this.newVariantsData = [];
        this.lastPosition = this.btnDeleteImg.length;
        this.imageUploadItems = [];
        this.imageType = 'png,.webp,.jpg,.jpeg,.gif';
        this.imagesUploadZone = new Dropzone(this.uploadImages, {
            createImageThumbnails: true,
            url: '/products/upload-images',
            uploadMultiple: true,
            acceptedFiles: '.' + this.imageType,
            addRemoveLinks: true,
            headers: {
              'X-CSRF-TOKEN': this.token
            },
            dictDefaultMessage: "Drop image files here to upload and add to product"
        });
        
        this.infoStatus = false;
        this.imagesDeleteStatus = false;
        this.imageStatus = false;
        this.variantStatus = false;

        this.formAddVariant = this.querySelector("#product-add-variant");
        
        this.addImageVariantBtn = this.getElementsByClassName("new-variant-image");
        this.imageNewVariant = '';
        this.addVariantBtn = this.querySelector("#new-variant-action");
        this.addVariantBtn.addEventListener("click", this.addNewRowVariant.bind(this));
        this.btnDeleteVariant = this.querySelectorAll(".delete-variant");

        Array.from(this.btnDeleteVariant).forEach(function(element){
            element.addEventListener("click", this.getVariantsDelete.bind(this));
        }, this);
        
        Array.from(this.addImageVariantBtn).forEach(function(element){
            if(element.classList.contains("add_to_variant")) {
                element.classList.remove()
            }
            element.addEventListener("click", this.addImageVariant.bind(this));
        }, this);
        
        Array.from(this.btnDeleteImg).forEach(function(element){
            element.addEventListener("click", this.getImagesDelete.bind(this));
        }, this);
        this.imagesUploadZone.on("success", this.onUploadComplete.bind(this));
        
        this.btnSubmit.addEventListener("click", this.editHandles.bind(this));
        
        this.btnEditMetafield = this.querySelectorAll(".edit-metafield");
        this.btnDeleteMetafield = this.querySelectorAll(".delete-metafield");
        
        Array.from(this.btnEditMetafield).forEach(function(element){
            element.addEventListener("click", this.actionEditMetafield.bind(this));
        }, this);
        Array.from(this.btnDeleteMetafield).forEach(function(element){
            element.addEventListener("click", this.actionDeleteMetafield.bind(this));
        }, this);
        
    }
    getImagesDelete(e) {
        e.preventDefault();
        let item = e.currentTarget;
        let parent = item.parentElement;
        let image_id = parent.getElementsByTagName("img")[0].getAttribute("data-image-id");
        if(parent.classList.contains("delete")) {
            parent.classList.remove("delete");
            let index = this.imagesDelete.indexOf(image_id);
            if (index !== -1) {
                this.imagesDelete.splice(index, 1);
            }
        }else{
            parent.classList.add("delete");
            this.imagesDelete.push(image_id);
        }
        console.log(this.imagesDelete); 
    }
    onUploadComplete(file, response) {
        console.log(file);
        let imageData = file.dataURL;
        let imageName = file.name;
        
        let imageType = this.imageType.split(',.');
        for(let i=0; i <= imageType.length; i++) {
            imageData = imageData.replace("data:image/"+imageType[i]+";base64,", "");
        }
        
        imageName = imageName.replaceAll(" ", "-");
        this.imageUploadItems.push({imageName, imageData});
    }
    editHandles(e) {
        
        e.preventDefault();
        
        this.editProductInfo();

        this.deleteImageInterval = setInterval(this.deleteImage.bind(this), 500);

        this.addImageInterval = setInterval(this.addProductImages.bind(this), 500);

        this.deleteVariantInterval = setInterval(this.deleteVariant.bind(this), 500);

        let has_call = false;
        if(this.newVariantsData.length > 0 && has_call == false) {
            this.addVariantHandle();
            has_call = true;
        }
        
        // this.editProductVariants.bind(this); 
        this.editMetafieldsData = this.getMetafieldsHasEdit();
        this.editMetafieldInterval = setInterval(this.editMetafields.bind(this), 500);

        this.deleteMetafieldsData = this.getMetafieldsHasDelete();
        this.deleteMetafieldInterval = setInterval(this.deleteMetafields.bind(this), 500);
    }

    editProductInfo() {
        
        let title = this.querySelector("input[name='title']");
        let body_content = this.querySelector("textarea[name='body_content']");
        let seo_title = this.querySelector("input[name='seo_title']");
        let seo_handle = this.querySelector("input[name='seo_handle']");
        let seo_description = this.querySelector("textarea[name='seo_description']");
        let formData = new FormData();
        
        formData.append("id", this.id);
        formData.append("title", title.value);
        formData.append("body_content", body_content.value);
        formData.append("seo_handle", seo_handle.value);
        formData.append("_token", this.token);
    
        fetch("/products/edit", {
            method: "POST",
            body: formData
          })
          .then(res => res.json())
          .then(result => {
            let productData = result['params'];
            title.value = productData['title'];
            body_content.value = productData['body_html'];
            seo_handle.value = productData['handle'];
          });
    }   
    deleteImage() {
        if(this.imagesDelete.length > 0) {
            var imageDelete = this.imagesDelete.shift();
            let formData = new FormData();
            formData.append("id", this.id);
            formData.append("image", imageDelete);
            formData.append("_token", this.token);
            
            fetch("/products/delete-image", {
                method: "POST",
                body: formData
            })
            .then(res => {
                console.log(res);
            });
        }

        if (this.imagesDelete.length == 0) {
            clearInterval(this.deleteImageInterval);
            return;
        }
    }
    addProductImages() {
        if (this.imageUploadItems.length > 0) {
            var imageAdd = this.imageUploadItems.shift();
            let attachment = imageAdd['imageData'];
            let filename = imageAdd['imageName'];
            this.lastPosition += 1;
            let formData = new FormData();
            formData.append("id", this.id);
            formData.append("attachment", attachment);
            formData.append("filename", filename);
            formData.append("position", this.lastPosition);
            formData.append("_token", this.token);

            fetch("/products/add-image", {
                method: "POST",
                body: formData
            })
            .then(res => {
                console.log(res);
            });
        }
        
        if (this.imageUploadItems.length == 0) {
        clearInterval(this.addImageInterval);
        return;
        }
    }
    addImageVariant(e) {
        e.preventDefault();
        Array.from(this.addImageVariantBtn).forEach(function(element){
            if(element.classList.contains("add_to_variant")) {
                element.classList.remove("add_to_variant");
            }
            this.imageNewVariant = '';
        }, this);
        let item = e.currentTarget;
        item.classList.add("add_to_variant");
        this.imageNewVariantId = item.getAttribute("data-image-id");
        this.imageNewVariantSrc = item.querySelector("img").getAttribute("src");
        this.imageNewVariant = {
            "id" : this.imageNewVariantId,
            "src" : this.imageNewVariantSrc
        }
        
    }
    getVariantsDelete(e) {
        e.preventDefault();
        
        let parentColumn = e.target.parentElement;
        let parentRow = parentColumn.parentElement;
        let variant_id = parentRow.getAttribute("data-variant-id");
        
        if(parentRow.classList.contains("delete")) {
            parentRow.classList.remove("delete");
            e.target.innerText = "Delete";
            let index = this.variantsDelete.indexOf(variant_id);
            if (index !== -1) {
                this.variantsDelete.splice(index, 1);
            }
        }else {
            parentRow.classList.add("delete");
            this.variantsDelete.push(variant_id);
            e.target.innerText = "Cancel";
        }
    }
    deleteVariant(e) {
        if(this.variantsDelete.length > 0) {
            var variantDelete = this.variantsDelete.shift();
            let formData = new FormData();
            formData.append("product_id", this.id);
            formData.append("variant_id", variantDelete);
            formData.append("_token", this.token);
            
            fetch("/products/delete-variant", {
                method: "POST",
                body: formData
            })
            .then(res => {
                
                if(res['status'] == 200) {
                    let variantRows = this.formVariants.querySelectorAll("tr");
                    for(let i = 0; i < variantRows.length; i++) {
                        let variantRow = variantRows[i];
                        let variantId = variantRow.getAttribute("data-variant-id");
                        if (variantId == variantDelete) {
                            variantRow.remove();
                        };
                    }
                } 
            });
        }

        if (this.variantsDelete.length == 0) {
            clearInterval(this.deleteVariantInterval);
            
            return;
        }
    }
    addNewRowVariant(e) {
        e.preventDefault();
        let column = e.target.parentElement;
        let row = column.parentElement;
        
        let image_id = this.imageNewVariantId;
        let image_src = this.imageNewVariantSrc.replace("&width=80", "&width=150");
        let option1 = row.querySelector("input[name='new-variant-option1']").value;
        let option2 = row.querySelector("input[name='new-variant-option2']").value;
        let price = row.querySelector("input[name='new-variant-price']").value;
        let compareprice = row.querySelector("input[name='new-variant-compareprice']").value;
        

        
        this.newRowData = [this.imageNewVariant, option1, option2, price, compareprice];
       
        let new_row = document.createElement("tr");
        let html = "";
        for(let i = 0; i <= this.newRowData.length; i++) {
            if(i == 0) {
                html += "<td><img src='" + image_src + "' data-image-id='"+ image_id +"'></td>";
            }else if(i == 1) {
                html += "<td>" + option1 + " / " + option2 + "</td>";
                html += "<td>" + option1 + "</td>";
            }else if(i == this.newRowData.length){
                html += "<td><a href='#' class='delete-new-row'>Cancel</a></td>";
            }else{
                html += "<td>" + this.newRowData[i] + "</td>";
            }
            
        }
        new_row.classList.add("new-variant");
        
        new_row.innerHTML = html;
        
        this.formVariants.querySelector("tbody").appendChild(new_row);
        this.refreshFormAddVariant();
        
        this.btnCancel = this.getElementsByClassName("delete-new-row");
        Array.from(this.btnCancel).forEach(function(element){
            element.addEventListener("click", this.cancelNewVariant.bind(this));
        }, this);
        this.getVariantsNew();
        
        
    }
    refreshFormAddVariant() {
        Array.from(this.addImageVariantBtn).forEach(function(element){
            if(element.classList.contains("add_to_variant")) {
                element.classList.remove("add_to_variant");
            }
        }, this);
        let form_input = this.formAddVariant.querySelectorAll("input");
        Array.from(form_input).forEach(function(element){
            element.value = '';
        }, this);
    }
    cancelNewVariant(e) {
        e.preventDefault();
        let tr = e.target.parentElement.parentElement;
        tr.remove();
        this.getVariantsNew();
    }
    addVariantHandle() {
        
        this.addVariantInterval = setInterval(this.addVariant.bind(this), 500);
    }
    addVariant() {
        if(this.newVariantsData != undefined && this.newVariantsData.length > 0) {
            var rowData = this.newVariantsData.shift();
            
            let formData = new FormData();
            formData.append("product_id", this.id);
            formData.append("image_id", rowData['image_id']);
            formData.append("option1", rowData['option1']);
            formData.append("option2", rowData['option2']);
            formData.append("price", rowData['price']);
            formData.append("compareprice", rowData['compareprice']);
            
            formData.append("_token", this.token);
            
            fetch("/products/add-variant", {
                method: "POST",
                body: formData
            })
            .then(res => res.json())
            .then(result => {
                let new_variant_id = result.variant.id;
                let newVariants = this.formVariants.querySelectorAll(".new-variant");
                
                newVariants[0].setAttribute("data-variant-id", new_variant_id);
                newVariants[0].removeAttribute("class");
                let oldBtn = newVariants[0].querySelector(".delete-new-row");
                oldBtn.remove();
                
                let newBtn_html = "<a href='#' class='delete-variant'>Delete</a>";
                newVariants[0].lastElementChild.innerHTML = newBtn_html;
                let newBtn = newVariants[0].querySelector(".delete-variant");
                newBtn.addEventListener("click", this.getVariantsDelete.bind(this));
            });
        }

        if (this.imagesDelete.length == 0) {
            clearInterval(this.deleteImageInterval);
            return;
        }
    }
    getVariantsNew() {
        
        let newVariants = this.formVariants.querySelectorAll(".new-variant");
        this.newVariantsData = [];
        for(let i = 0; i < newVariants.length; i++) {
            
            let image_id = newVariants[i].querySelector("img").getAttribute("data-image-id");
            let option1 = newVariants[i].querySelectorAll("td")[2].innerText;
            let option2 = newVariants[i].querySelectorAll("td")[3].innerText;
            let price = newVariants[i].querySelectorAll("td")[4].innerText;
            let compareprice = newVariants[i].querySelectorAll("td")[5].innerText;
            
            this.newVariantsData.push({image_id, option1, option2, price, compareprice});
        }
    }
    editProductVariants() {
        
    }

    actionEditMetafield(e) {
        e.preventDefault();
        let column = e.target.parentElement;
        let row = column.parentElement;
        row.removeAttribute("class");
        row.classList.add("edited");
        let textarea = row.querySelectorAll("textarea");
        for(let i = 0; i < textarea.length; i++){
            textarea[i].removeAttribute("readonly");
            textarea[i].style.borderColor = "#008000";
            textarea[i].style.background = "rgba(0, 128, 0, .1)";
        }
    }
    actionDeleteMetafield(e) {
        e.preventDefault();
        
        let column = e.target.parentElement;
        let row = column.parentElement;
        if(row.classList.contains("deleted")) {
            row.removeAttribute("class");
            e.target.innerText = "Delete";
        }else{
            row.removeAttribute("class");
            row.classList.add("deleted");
            e.target.innerText = "Cancel";
        }
       
    }
    editMetafields() {
        if(this.editMetafieldsData != undefined && this.editMetafieldsData.length > 0) {
            let rowData = this.editMetafieldsData.shift();
            
            let formData = new FormData();
            formData.append("id", rowData['id']);
            formData.append("namespace", rowData['namespace']);
            formData.append("key", rowData['key']);
            formData.append("value", rowData['value']);
            formData.append("description", rowData['description']);
            formData.append("type", rowData['type']);
            
            formData.append("_token", this.token);
            
            fetch("/product/edit-metafield", {
                method: "POST",
                body: formData
            })
            .then(res => res.json())
            .then(result => {
                
            });
        }

        if (this.imagesDelete.length == 0) {
            clearInterval(this.deleteImageInterval);
            return;
        }
        
    }
    getMetafieldsHasEdit() {
        let rowsEdit = this.querySelectorAll("#product-metafields .edited");
        let rowsData = [];
        Array.from(rowsEdit).forEach(function(row){
            let rowData = [];
            let columns = row.querySelectorAll("td");
            for(let i = 0; i < (columns.length - 1); i++) {
                let field_name = columns[i].getAttribute("data-field-name");
                let field_value = '';
                if(field_name == 'type') {
                    field_value = columns[i].querySelector("select").value;
                }else if(field_name == "value" || field_name == "description") {
                    field_value = columns[i].querySelector("textarea").value;
                }else{
                    field_value = columns[i].innerText;
                }
                rowData[field_name] = field_value;
            }
            rowsData.push(rowData);
        }, this);
        return rowsData;
    }
    deleteMetafields() {
        if(this.deleteMetafieldsData != undefined && this.deleteMetafieldsData.length > 0) {
            let metafieldDelete = this.deleteMetafieldsData.shift();
            let formData = new FormData();
            formData.append("product_id", this.id);
            formData.append("metafield_id", metafieldDelete);
            formData.append("_token", this.token);
            
            fetch("/product/delete-metafield", {
                method: "POST",
                body: formData
            })
            .then(res => {
                
                if(res['status'] == 200) {
                    let deleteRows = this.querySelectorAll("#product-metafields .deleted");
                    for(let i = 0; i < deleteRows.length; i++) {
                        let deleteRow = deleteRows[i];
                        let deleteId = deleteRow.getAttribute("metafield-id");
                        if (deleteId == metafieldDelete) {
                            deleteRow.remove();
                        };
                    }
                } 
            });
        }
    }
    getMetafieldsHasDelete() {
        let rowsDelete = this.querySelectorAll("#product-metafields .deleted");
        let rowsData = [];
        Array.from(rowsDelete).forEach(function(row){
            let metafield_id = row.getAttribute("metafield-id");
            rowsData.push(metafield_id);
        }, this);
        return rowsData;
    }
}
customElements.define("product-edit", ProductEdit);