import ListTable from "../global/list-table";

class ProductsListTable extends ListTable{
  constructor(){
    super();
    this.displayColumns = [
      {key: 'index', label: "Index"},
      {key: 'id', label: "ID"},
      {key: 'title', label: "Title"},
      {key: 'body_html', label: "Short Content"},
      {key: 'vendor', label: "Vendor"},
      {key: 'template_suffix', label: "Template"},
      {key: 'published_at', label: "Publish Date"},
    ];
    this.exportColumns = [
      {key: 'id', label: "ID"},
      {key: 'handle', label: "Handle"},
      {key: 'title', label: "Title"},
      {key: 'tags', label: "Tags"},
    ];
    this.init();

    this.actions = this.getElementsByClassName("list-actions")[0];
    this.submit = this.getElementsByClassName("submit-action")[0];

    this.submit.addEventListener("click", this.actionHandle.bind(this));
   
    this.btnDownload = this.getElementsByClassName("action--download")[0];
    this.btnDownload.classList.add("hide");

    this.reminder = this.getElementsByClassName("export-reminder")[0];
    this.reminder.classList.add("hide");

    this.initVendorFilter();

    this.searchBtn = this.getElementsByClassName("searching")[0];
    this.searchBtn.addEventListener("click", this.searchAction.bind(this));

    window.addEventListener("keyup", function(e){
      // console.log(e.keyCode);
      if(e.keyCode === 13) {
        this.searchAction();
      }
    }.bind(this));
  }
}

customElements.define("products-list-table", ProductsListTable);