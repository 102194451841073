class LiteYoutubeEmbedElement extends HTMLElement{
  constructor(){
    super();

    this.regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

    this.youtubeUrlElement = this.querySelector("#youtube-url");
    this.youtubeID = "";
    this.btnGenerate = this.querySelector(".btn-generate-code");
    this.embedCodeElement = this.querySelector("#lite-youtube-embed-code");
    this.formElement = this.querySelector("form");

    this.youtubeUrlElement.addEventListener("change", this.onChangeYoutubeUrlElementCallback.bind(this));
    this.btnGenerate.addEventListener("click", this.onGenerateCodeCallback.bind(this));
  }

  getYoutubeIDFromURL(url){
    let match = url.match(this.regExp);
    return match[2];
  }

  validateYouTubeUrl(url) {    
    if (url != undefined || url != '') {     
      let match = url.match(this.regExp);
      return match && match[2].length == 11;
    }

    return false
}

  onChangeYoutubeUrlElementCallback(e){
    e.preventDefault();
    let url = e.currentTarget.value;

    if(this.validateYouTubeUrl(url)){
      this.youtubeID = this.getYoutubeIDFromURL(url);
    }

    return false;
  }

  onGenerateCodeCallback(e){
    e.preventDefault()
    if (! this.formElement.checkValidity()) {
      e.stopPropagation();
      console.warn("Form is not valid");
      
      this.formElement.classList.add('was-validated')

      return false;
    }

    let code = '<div class="lite-youtube-wrapper"><lite-youtube videoid="' + this.youtubeID + '"></lite-youtube></div>';
    this.embedCodeElement.value = code;

    return false;
  }
}

customElements.define("lite-youtube-embed", LiteYoutubeEmbedElement);