import TableHandle from "../global/table-handle-2";
class PagesPCEList extends HTMLElement {
    constructor(){
        super();
        this.type = this.dataset.type;
        this.tableWrapper = this.getElementsByClassName("table-wrapper")[0];
        this.displayColumns = [
            {key: 'index', label: "Index"},
            {key: 'handle', label: "Handle"},
            {key: 'title', label: "Title"},
            {key: 'body_html', label: "Short Content"},
            {key: 'template_suffix', label: "Template"},
        ];
        this.loading = this.getElementsByClassName('loading-container')[0];
        this.per_page = 50;

        this.tableHandle = new TableHandle(this.type, this.tableWrapper, null, "shopify", this.displayColumns);
        
        
        this.getAllPCEPages(); 
    }

    
    getAllPCEPages() {
        fetch("/pages/getallpce", {method: "GET"})
        .then(res => res.json())
        .then( (res) => {
            this.data = res.pages;
            if(this.data.length > 0) {
                this.generateFirstPaged(this.data);
                this.generatePagination();
            }
        })
    }
    generateFirstPaged(data) {
        let pagesDisplay = [];
    
        for(let i = 0; i < this.per_page; i++) {
            pagesDisplay.push(data[i]);
        }
        this.tableHandle.setData(pagesDisplay);
        this.tableHandle.generateTable();

        this.loading.style.display = "none";  
    }
    generatePagination() {
        let total_paged = this.data.length / this.per_page;

        if(!Number.isInteger(total_paged)) {
            total_paged = parseInt(total_paged) + 1;
        }
        
        this.pagination = document.createElement('div');
        this.pagination.classList.add('pagination');

        let html = '';
        for(let i = 1; i <= total_paged; i++) {
            if(i == 1) {
                html += '<a href="javascript::void(0);" class="page page-active">'+ i +'</a>';
            }else {
                html += '<a href="javascript::void(0);" class="page">'+ i +'</a>';
            }
        }
        this.pagination.innerHTML = html;

        this.appendChild(this.pagination);

        let paged = this.pagination.getElementsByClassName('page');
        
        Array.from(paged).forEach(function(item){
            item.addEventListener("click", this.actionPagination.bind(this));
        }, this);
    }   

    actionPagination(e) {
        e.preventDefault();
        let paged = e.target.innerText;

        let start_index = this.per_page * (paged - 1);
        let end_index = start_index + this.per_page;

        if(this.data.length <= end_index) {
            end_index = this.data.length - 1;
        }

        let pagesDisplay = [];
    
        for(let i = start_index; i < end_index; i++) {
            pagesDisplay.push(this.data[i]);
        }
        
        this.updateTable(pagesDisplay);
        this.updatePagination(paged);
    }

    updateTable(data) {
        let table = this.tableWrapper.getElementsByClassName('table')[0];
        this.tableWrapper.removeChild(table);

        this.tableHandle.setData(data);
        this.tableHandle.generateTable();
    }

    updatePagination(current_paged) {
        let paged = this.pagination.getElementsByClassName('page');

        Array.from(paged).forEach(function(item){
            if(item.classList.contains('page-active')) {
                item.classList.remove('page-active');
            }

            if(item.innerText == current_paged) {
                item.classList.add('page-active');
            }
        }, this);
    }
}
customElements.define("page-pce-list", PagesPCEList);