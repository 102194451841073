import Dropzone from "dropzone";
import FileHandle from '../global/file-handle';
import TableHandle from '../global/table-handle';
class ProductBulkTags extends HTMLElement{
  constructor(){
    super();
    this.type = "product";
    this.displayColumns = [];
    this.form = this.getElementsByClassName("dropzone")[0];
    this.tableWrapper = this.getElementsByClassName("table-wrapper")[0];
    this.updateTagRoute = this.getElementsByClassName("update-tag-route")[0].innerText;
    this.getInfoRoute = this.getElementsByClassName("get-info-route")[0].innerText;
    this.btnView = this.getElementsByClassName("btn-viewtags")[0];
    this.btnBulkTag = this.getElementsByClassName("btn-start-bulk")[0];

    this.token = document.head.querySelector('meta[name="csrf-token"]').content;

    this.readyToBulk = false;
    this.bulkData = [];
    this.products = [];

    this.bulkTagsUploadZone = new Dropzone(this.form, {
      createImageThumbnails: false,
      addRemoveLinks: true,
      headers: {
        'X-CSRF-TOKEN': this.token
      },
      acceptedFiles: ".csv",
      dictDefaultMessage: "Drop Bulk Tags CSV file here to upload and start process"
    });
    
    
    this.bulkTagsUploadZone.on("success", this.onUploadComplete.bind(this));
    this.btnView.addEventListener("click", this.onViewTags.bind(this));
    this.btnBulkTag.addEventListener("click", this.onBulkTags.bind(this));
  }

  onUploadComplete(file, response) {
    this.bulkData = response.content;
    this.status = "loaded";
    this.ready();
    let tableHandle = new TableHandle(this.type, this.tableWrapper, this.bulkData, this.status, this.displayColumns);
    tableHandle.generateTable();
    
  }

  ready(){
    this.readyToBulk = true;
  }

  isReady(){
    return this.readyToBulk;
  }
  
  onViewTags(){
    if(!this.isReady()){
      console.error("Data are not ready, please upload a Bulk Tags CSV file first!")
      return;
    }
    
    this.generateTags();
    
    this.status = "view-tags";
    let tableHandle = new TableHandle("product", this.tableWrapper, this.bulkData, this.status, '');
    tableHandle.generateTable();
  }

  generateTags(){
    this.bulkData[0].push("Tags");

    for(let i = 1; i < this.bulkData.length; i++){
      let data = this.bulkData[i];
      let tags = [];
      for(let j = 2; j < data.length; j++){
        if(data[j].trim()){

          let values = data[j].split(",");
  
          for(var k in values){
            let value = values[k].trim();

            if(value.length > 0){
              let tag = this.bulkData[0][j] + "_" + value;
              tags.push(tag.trim());
            }
          }
        }
      }

      this.bulkData[i].push(tags.join(", "));
    }
  }

  onBulkTags(){
    this.updateInterval = setInterval(this.updateProductsTags.bind(this), 500);
  }
  
  updateProductsTags(){
    let p = this.products.shift();

    /* data has ID */
    if(p[0].trim()){
      console.log("Updating " + p[0]);
      this.updateProductTag(p[0], p[p.length - 1]);
    }
    else if(p[1].trim()){
      console.log("Updating " + p[1]);
      this.getProductIDByHandle(p[1])
        .then(id => this.updateProductTag(id));
    }

    if(this.products.length == 0){
      clearInterval(this.updateInterval);
    }
  }

  updateProductTag(id, tags){

    let formData = new FormData();
    formData.append("_token", this.token);
    formData.append("id", id);
    formData.append("tags", tags);

    fetch(this.updateTagRoute, { method: "POST", body: formData })
      .then(res => res.json())
      .then(res => this.updateProductRowStatus(res.id, res.status));

  }

  updateProductRowStatus(id, status){
    console.log("Product " + id + " update " + status);
    this.getElementsByClassName("product-"  + id)[0].classList.add("bg-" + status);
  }

  /* Return a Promise which fetch data from Shopify Product */
  getProductIDByHandle(handle){
    let formData = new FormData();

    formData.append("_token", this.token);
    formData.append("handle", handle);

    return fetch(this.getInfoRoute, {method: "POST", body: formData})
              .then(res => res.json())
              .then( (res) => {
                this.getElementsByClassName("product-" + handle)[0].classList.add("product-" + res.id);
                return res.id;
              })
              .catch(e => { console.error(e); });
  }

  slugify(str){
    return str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }
    
}

customElements.define("product-bulktags", ProductBulkTags);

