class AutocompleteBox extends HTMLElement{
    constructor() {
        super();
        
        const listCountries = this.createData();

        this.buildDropdown(listCountries);
        
        this.searchField = this.querySelector('.search-field');
        this.searchField.addEventListener('click', this.showChoosebox.bind(this));
        this.searchField.addEventListener('keyup', this.findResults.bind(this, listCountries));
        
        this.bindEvents();
        this.hiddenChoosebox();
    }

    createData() {
        let data = [];
        let selectField = this.querySelector('.field-select');
        selectField.querySelectorAll('option').forEach((el) =>{
            el.addEventListener('click', this.selectCountry.bind(this))
            data.push({'id': el.value, name: el.textContent.trim()})
        });
        return data;
    }

    buildDropdown(data) {
        const parentBox = this.querySelector('.inner-autocomplete-box');
        const hiddenField = this.querySelector('.value-autocomplete-box');

        let divBox = document.createElement('div');
        divBox.id = 'box-dropdown';
        divBox.className = 'box-dropdown';

        let dropdownChoose = document.createElement('div');
        dropdownChoose.className = 'dropdown-choose';
        dropdownChoose.id = 'dropdown-choose';
        dropdownChoose.style.display = 'none';

        let searchBox = document.createElement('div');
        searchBox.className = 'search-choose form-floating';

        let searchField = document.createElement('input');
        searchField.type = 'text';
        searchField.id = 'search-field';
        if(hiddenField.value){
            searchField.value = hiddenField.value;
        }
        searchField.className = 'dropdown-search-input search-field form-control';
        searchField.setAttribute('placeholder', hiddenField.dataset.placeholder);
        searchBox.append(searchField);
        let labelField = document.createElement('label');
        labelField.innerText = hiddenField.dataset.label;
        labelField.setAttribute('for', hiddenField.dataset.label);
        searchBox.append(labelField);

        let results_Ul = document.createElement('ul');
        results_Ul.className = 'ul-results';

        const arrCountries = data;
        arrCountries.forEach(element => {
            let result_li = document.createElement('li');
            result_li.innerText = element.name;
            result_li.setAttribute('data-option-id', element.id);
            results_Ul.append(result_li);
        });

        dropdownChoose.append(results_Ul);

        divBox.append(searchBox);
        divBox.append(dropdownChoose);

        parentBox.append(divBox);
    }

    bindEvents(e) {

        document.querySelectorAll('autocomplete-box').forEach((el) => {
            el.querySelectorAll('li').forEach((ele) =>
                ele.addEventListener('click', this.selectCountry.bind(this))
            );
        });
    }

    showChoosebox(e){
        e.preventDefault();
       
        let dropdownBox = this.querySelector('.dropdown-choose');
        let country_dropdown = this.querySelector('.box-dropdown');
        dropdownBox.style.display = "block";
        country_dropdown.parentElement.classList.add('show-result');
    }

    hiddenChoosebox(e){
        window.addEventListener('click', function(evt){
            document.querySelectorAll('autocomplete-box').forEach((el) => {
                let dropdownBox = el.querySelector('.dropdown-choose');
                let country_dropdown = el.querySelector('.box-dropdown');
                
                if (!el.querySelector('.search-field').contains(evt.target)){
                    if(country_dropdown.parentElement.classList.contains('show-result')){
                        dropdownBox.style.display = "none";
                        country_dropdown.parentElement.classList.remove('show-result');
                    }
                }
            });
        });
    }

    findResults(suggestions, evt){
        
        this.showChoosebox.bind(this);
        let resultBox = this.querySelector('.ul-results');
        let findData = evt.target.value;
        let resultsArray = [];
        let emptyArray = [];

        

        if(findData){
            emptyArray = suggestions.filter((data)=>{
                return data.name.toLocaleLowerCase().includes(findData.toLocaleLowerCase()); 
            });
            
            if (emptyArray.length > 0) {

                resultsArray = suggestions.map((data)=>{
                    if(emptyArray.some(el => el.id === data.id)){
                        if(data.id!=''){
                            return data = '<li data-option-id="'+data.id+'">'+ data.name +'</li>';
                        } else {
                            return data = '<li data-option-id="'+data.id+'" class="hide">'+ data.name +'</li>';
                        }
                    } else {
                        return data = '<li data-option-id="'+data.id+'" class="hide">'+ data.name +'</li>';
                    }
                    
                });
                
                this.showSuggestions(resultBox, resultsArray);

                if (evt.keyCode == 13) {
                    evt.preventDefault();
                    resultBox.querySelectorAll('li:not(.hide)').forEach((el, index) => {
                        if(index==0){
                            el.click();
                        }
                    });
                }
                
            } else {
                this.showSuggestions(resultBox, emptyArray);
            }
            
        } else {
            resultBox.querySelectorAll('li').forEach((el, index) => {
                el.classList.remove('hide');
            });
            if (evt.keyCode == 13) {
                evt.preventDefault();
                resultBox.querySelectorAll('li:not(.hide)').forEach((el, index) => {
                    if(index==0){
                        el.click();
                    }
                });
            }
        }
    }

    showSuggestions(resultBox, list){
        let listData;
        // let resultBox = this.querySelector('.ul-results');
        // console.log('111');
        if(list.length){
            listData = list.join('');
        } else {
            listData = '<li data-option-id="">No results...</li>';
        }
        resultBox.innerHTML = listData;
        
        this.bindEvents();
    }

    selectCountry(e){
        let select = e.target;
        let select_id = select.getAttribute('data-option-id');
        
        let listCountries = this.createData();
        let search_field = this.querySelector('.search-field');
        let hidden_field_id = this.querySelector('.value-autocomplete-box');
        
        let selected = listCountries.find(el => el.id === select_id);
        
        hidden_field_id.value = selected.id;
        search_field.value = selected.name;
       
        let country_dropdown = this.querySelector('.box-dropdown');
        let dropdownBox = this.querySelector('.dropdown-choose');
        dropdownBox.style.display = "none";
        country_dropdown.parentElement.classList.remove('show-result');
    }
    
}
customElements.define("autocomplete-box", AutocompleteBox);