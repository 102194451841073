class ShopifyStoreForm extends HTMLElement{
  
  constructor(){
    super();
    this.selector = this.getElementsByClassName('shopify-store')[0];
    this.form = this.getElementsByTagName("form")[0];
    this.token = document.querySelector("meta[name=csrf-token]").content;

    this.selector.addEventListener("change", this.changeStore.bind(this));
  }

  changeStore(e){
    e.preventDefault();

    const formData = new FormData();

    formData.append("store", e.target.value);
    formData.append("_token", this.token);
    formData.append("action", "setStoreID");

    fetch("/stores", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      console.log(result);

      location.reload();
    });

    console.log("OK");


    return false;
  }
}

customElements.define("shopify-store-form", ShopifyStoreForm);