class ESFormFieldElement extends HTMLElement{
    constructor(){
        super();
        // this.extraFieldMeta = this.querySelectorAll('.extra-field-meta');
        // this.fieldType = this.querySelector('.form_field_type');
        // this.fieldTypeValue = this.fieldType.value;
        // this.showCurrentFieldMeta(this.fieldTypeValue);
        // this.fieldType.addEventListener('change', this.showFieldMeta.bind(this));
        
    }

    connectedCallback() {
        this.boxMeta = this.querySelector('.box-field-meta');
        this.extraFieldMeta = this.querySelectorAll('.extra-field-meta');
        this.fieldClasses = this.querySelector('.field-meta-classes');
        this.fieldValidate = this.querySelector('.field-validate');
        this.fieldType = this.querySelector('.form_field_type');
        this.fieldTypeValue = this.fieldType.value;
        this.showCurrentFieldMeta(this.fieldTypeValue);
        this.fieldType.addEventListener('change', this.showFieldMeta.bind(this));
        this.btnDeleteField = this.querySelector(".btn-delete-form-field");
        if(this.btnDeleteField){
            this.btnDeleteField.addEventListener('click', this.removeField.bind(this));
        }

        this.btnAddField = this.querySelector(".btn-add-form-field");
        if(this.btnAddField){
            this.btnAddField.addEventListener('click', this.addField.bind(this));
        }
        
    }
    showFieldMeta(e){
        let selectType = e.target.value;

        this.fieldClasses.classList.remove('hidden');
        this.fieldValidate.classList.remove('hidden');
        this.boxMeta.classList.remove('hidden');
        if(selectType === 'phone'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-placeholder')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'text' || selectType === 'url' || selectType === 'email'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-placeholder') || el.classList.contains('field-meta-single-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'range'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-range') || el.classList.contains('field-meta-single-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'dropdown' || selectType === 'radio'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-choices') || el.classList.contains('field-meta-single-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'checkbox'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-choices') || el.classList.contains('field-meta-multi-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'heading'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-heading')){
                    el.classList.add('show');
                    this.fieldClasses.classList.add('hidden');
                    this.fieldValidate.classList.add('hidden');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'blank-text'){
            this.fieldClasses.classList.add('hidden');
            this.fieldValidate.classList.add('hidden');
            this.boxMeta.classList.add('hidden');
            this.extraFieldMeta.forEach((el) => {
                el.classList.remove('show');
            });
        } else if(selectType === 'splitter'){
            this.fieldClasses.classList.add('hidden');
            this.fieldValidate.classList.add('hidden');
            this.boxMeta.classList.add('hidden');
            this.extraFieldMeta.forEach((el) => {
                el.classList.remove('show');
            });
        }
        
    }

    showCurrentFieldMeta(fieldTypeValue){
        let selectType = fieldTypeValue;

        this.fieldClasses.classList.remove('hidden');
        this.fieldValidate.classList.remove('hidden');
        this.boxMeta.classList.remove('hidden');
        if(selectType === 'phone'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-placeholder')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'text' || selectType === 'url' || selectType === 'email'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-placeholder') || el.classList.contains('field-meta-single-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'range'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-range') || el.classList.contains('field-meta-single-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'dropdown' || selectType === 'radio'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-choices') || el.classList.contains('field-meta-single-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'checkbox'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-choices') || el.classList.contains('field-meta-multi-default-values')){
                    el.classList.add('show');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'heading'){
            this.extraFieldMeta.forEach((el) => {
                if(el.classList.contains('field-meta-heading')){
                    el.classList.add('show');
                    this.fieldClasses.classList.add('hidden');
                    this.fieldValidate.classList.add('hidden');
                } else {
                    el.classList.remove('show');
                }
            });
        } else if(selectType === 'blank-text'){
            this.fieldClasses.classList.add('hidden');
            this.fieldValidate.classList.add('hidden');
            this.boxMeta.classList.add('hidden');
            this.extraFieldMeta.forEach((el) => {
                el.classList.remove('show');
            });
        } else if(selectType === 'splitter'){
            this.fieldClasses.classList.add('hidden');
            this.fieldValidate.classList.add('hidden');
            this.boxMeta.classList.add('hidden');
            this.extraFieldMeta.forEach((el) => {
                el.classList.remove('show');
            });
        }
        
    } 

    removeField(e){
        let idField = e.target.getAttribute('data-field-id');
        let fieldListIdDelete = document.querySelector('.hidden-list-field-delete');
        let listIdDelete = fieldListIdDelete.value;
        if(idField.length === 0){
            //e.target.closest('es-form-field').remove();
            e.target.closest('.accordion-item').remove();
        } else {
            
            if(listIdDelete.length === 0){
                fieldListIdDelete.value = idField;
            } else {
                fieldListIdDelete.value = listIdDelete+';'+idField;
            }
            //e.target.closest('es-form-field').remove();
            e.target.closest('.accordion-item').remove();
        }
    }

    addField(e){
        document.querySelector(".btn-add-field-to-form").click();
    }
}
customElements.define("es-form-field", ESFormFieldElement);

class ConfirmDeleteElement extends HTMLElement{
    constructor(){
        super();
        this.button = this.querySelector('button');
        this.modal = document.getElementById('deleteConfirmModal');
        this.form = this.modal.querySelector('form');
        this.button.addEventListener('click', this.Confirm.bind(this));
    }


    Confirm(){
        let delete_id = this.button.getAttribute('data-id');
        let action = this.form.action;
        let newAction = action.replace('id', delete_id);
        this.form.action = newAction;
    }

   
}
customElements.define("confirm-delete", ConfirmDeleteElement);


class ESFormEntryElement extends HTMLElement{
    constructor(){
        super();
        
        this.form = this.querySelector('form');
        this.selectField = this.querySelector('select');
        this.selectField.addEventListener('change', this.submitForm.bind(this));
    }

    submitForm(e){
        let value = e.target.value;
        if(value){
            this.form.submit();
        }
    }
}
customElements.define("es-form-entry", ESFormEntryElement);