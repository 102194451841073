class GeoIpToggleStatusElement extends HTMLAnchorElement{
  connectedCallback(){

    this.changeStatusAction = document.querySelector("#change-status-url").value;
    this.token = document.head.querySelector('meta[name="csrf-token"]').content;
    this.addEventListener("click", this.clickCallback.bind(this));
  }

  clickCallback(e){
    let status = this.classList.contains("active") ? 1 : 0;
    this.switchStatus(status);
  }

  switchStatus(status){
    let formData = new FormData();
    let ruleId = this.dataset.ruleId;
    formData.append("id", ruleId);
    formData.append("status", status);
    formData.append("_token", this.token);

    fetch(this.changeStatusAction, { method: "post", body: formData })
      .then(res => res.json())
      .then(res => {
        document.querySelector("#geoip-list .rule-" + ruleId).switchStatus(res.data.status);
      })
      .catch(e => console.error(e));
  }
}
customElements.define("geoip-toggle-status", GeoIpToggleStatusElement, {extends: "a"});