import Dropzone from "dropzone";
import Papa from "papaparse";
import ListTable from "../global/list-table";
import FileHandle from "../global/file-handle";
import TableHandle from "../global/table-handle-2";
class ProductBulkMTField extends ListTable {
  constructor(){
    super();
    
    this.isLoadFileContent = true;
    this.fileHandle = new FileHandle(this);
    this.addEventListener("prm:fileLoaded", function(e){
      this.init(e);
    }.bind(this));
    

    this.btnImport = this.getElementsByClassName("btn-start-import")[0];
    
    this.btnImport.addEventListener("click", this.startImportProductMetafields.bind(this));
   
    
    this.namespaceElement = this.getElementsByClassName("namespace")[0];
    this.namespaceElement.addEventListener("change", (e) => this.namespace = e.currentTarget.value );
    
  }
  init(e) {
    this.data = e.detail.data;
    this.heading = e.detail.data.shift();
    this.recordsCount = this.data.length;

    this.generateMetaKey();
    this.generateImportData();
    this.generateTable();
  }
  generateImportData(){
    let content = this.data;

    this.importData = [];
    let index = 0;
    
    for(let i = 0; i < content.length; i++){
      let data = content[i];
      let id = data[0];
      let handle = data[1];
      for(let j = 2; j < data.length; j++){
        // dont add row with empty meta value
        if(data[j]){
          let rowData = [];
        
          rowData.push(id); /* Product ID Column */
          rowData.push(handle); /* Product Key Label Column */
          rowData.push(this.metaKey[j]); /* Product Meta Key */
          rowData.push(data[j]); /* Product Meta Value */
          rowData.push(index); /* Product ID Column */
  
          this.importData.push(rowData);
          index++;
        }
      }

    }
  }

  generateMetaKey(){
    this.metaKey = [];

    for(let i in this.heading){
      let key = this.slugify(this.heading[i]);
      this.metaKey.push(key);
    }
  }

  generateTable(){
    if(this.tableWrapper.getElementsByTagName("table").length > 0){
      this.tableWrapper.getElementsByTagName("table")[0].remove();
    }

    this.createTable();
    this.createTableHeading();
    
    this.createTableBody();
  }

  createTable(){
    this.table = document.createElement("table");
    this.tableHead = document.createElement("thead");
    this.tableBody = document.createElement("tbody");
    this.tableFoot = document.createElement("tfoot");

    this.table.classList.add("table", "table-striped", "table-hover");
    
    this.table.append(this.tableHead, this.tableFoot, this.tableBody);
    this.tableWrapper.append(this.table);

    this.tableWrapper.removeAttribute("hidden");

    this.table.classList.add(this.status);
  }

  createTableHeading(){
    let heading = this.heading;
    let tableHeadRow = document.createElement("tr");
    let tableFootRow = document.createElement("tr");
    
    let headToggleAll = document.createElement('th');
    let toggleAll = document.createElement("input");
    toggleAll.setAttribute("type", "checkbox");
    toggleAll.setAttribute("name", "toggle-all");
    toggleAll.classList.add("toggle-all");
    
    toggleAll.addEventListener("change", this.onToggleAll.bind(this));
    headToggleAll.append(toggleAll);
    
    let footToggleAll = headToggleAll.cloneNode(true);
    footToggleAll.childNodes[0].addEventListener("change", this.onToggleAll.bind(this));
    
    tableFootRow.append(headToggleAll);
    tableHeadRow.append(footToggleAll);

    /* ID Column */
    let firstColHead = this.createHeadCell("ID");
    tableFootRow.append(firstColHead.cloneNode(true));
    tableHeadRow.append(firstColHead);

    /* ID Column */
    let secondColHead = this.createHeadCell("Handle");
    tableFootRow.append(secondColHead.cloneNode(true));
    tableHeadRow.append(secondColHead);

    /* ID Column */
    let thirdColHead = this.createHeadCell("Meta Key");
    tableFootRow.append(thirdColHead.cloneNode(true));
    tableHeadRow.append(thirdColHead);

    /* ID Column */
    let fouthColHead = this.createHeadCell("Value");
    tableFootRow.append(fouthColHead.cloneNode(true));
    tableHeadRow.append(fouthColHead);

    

    this.tableHead.append(tableHeadRow);
    this.tableFoot.append(tableFootRow);

  }

  createTableBody(){
    let content = this.importData;
  
    for(let i = 0; i < content.length; i++){
      let data = content[i];
    
      let id = data[0];
      let handle = data[1];
      let key = data[2];
      let value = data[3];
      let index = data[4];
      
      let row = document.createElement("tr");

      row.classList.add("index-" + index);      
      row.classList.add(data[1].trim());
    
      let cellToggle = document.createElement("td");
      let toggle = document.createElement("input");
      
      toggle.setAttribute("type", "checkbox");
      toggle.setAttribute("name", "toggle");
      toggle.classList.add("toggle");
      toggle.setAttribute("value", "index-" + index);
      cellToggle.append(toggle);
      row.append(cellToggle);
    
      row.append(this.createCell(id)); /* Product ID Column */
      row.append(this.createCell(handle)); /* Product Key Label Column */
      row.append(this.createCell(key)); /* Product Meta Key */
      row.append(this.createCell(value)); /* Product Meta Value */

      this.tableBody.append(row);

    }
  }

  createHeadCell(text){
    let colHead = document.createElement("th");
    colHead.innerText = text;

    return colHead;
  }

  createCell(text){
    let cell = document.createElement("td");

    cell.innerText = text;
    return cell;
  }

  onToggleAll(e){
    let allToggler = this.getElementsByClassName("toggle");
    let allAllToggler = this.getElementsByClassName("toggle-all");
    let isChecked = e.currentTarget.checked;

    for(let i = 0; i < allToggler.length; i++){
      allToggler[i].checked = isChecked;
    }
    for(let i = 0; i < allAllToggler.length; i++){
      allAllToggler[i].checked = isChecked;
    }
  }

  validate(){
    let toggle = this.querySelectorAll(".toggle:checked");

    if(toggle.length == 0){
      alert("Please choose least 1 row.");
      return false;
    }

    if(this.namespace == ""){
      alert("Namespace must be enter value");
      return false;
    }

    return true;
  }

  get getBaseField(){
    return this.querySelector("[name=importby]:checked").value;
  }

  startImportProductMetafields() {
    if(!this.validate()){
      return;
    }

    let toggle = this.querySelectorAll(".toggle:checked");

    let indexHasChoose = [];
    for(let i = 0; i < toggle.length; i++) {
      let toggleValue = toggle[i].value;
      toggleValue = toggleValue.replace("index-","");
      indexHasChoose.push(parseFloat(toggleValue));
    }
    
    let data = this.importData;
    
    this.rowsImport = [];
    for(let j in indexHasChoose) {
      this.rowsImport.push(data[indexHasChoose[j]]);
    }

    this.importInterval = null;
    this.totalPages = this.rowsImport.length;
    this.startedCount = 0;
    this.doneCount = 0;

    this.progressBar.ariaValueMax = this.totalPages;
    this.progressCount.innerText = "0/" + this.totalPages;

    this.progress.classList.remove("hide");

    this.importInterval = setInterval(this.importProductMetafieldsToShopify.bind(this), 1000);
  }

  importProductMetafieldsToShopify() {
    this.currentData = this.rowsImport.shift();
    this.startedCount++;
    
    if(this.getBaseField == "id"){
      this.addMeta(this.currentData);
    }
    else{ // if(this.getBaseField == "handle")
      this.checkProductExistByHandle(this.currentData[1]);
    }
    
    if (this.rowsImport.length == 0) {
      clearInterval(this.importInterval);
      return;
    }
  }

  checkProductExistByHandle(handle){
    let formData = new FormData();

    formData.append("_token", this.token);
    formData.append("handle", handle);

    var that = this;
    fetch("/product/info", {method: "POST", body: formData})
        .then(res => res.json())
        .then( (res) => {
          let id = res.id;
          
          if(id != undefined && id) {
            that.currentData[0] = id;
            that.addMeta(this.currentData);
          } 
          that.countDone();
        })
        
  }

  addMeta(rowData){
    console.log(rowData);

    let formData = new FormData();
    formData.append("id", rowData[0]);
    formData.append("key", rowData[2]);
    formData.append("value", rowData[3]);
    formData.append("namespace", this.namespace);
    formData.append("description", '');
    formData.append("_token", this.token);

    if(rowData[3].includes("https://")) {
      formData.append("type", "url");
    }else{
      formData.append("type", "single_line_text_field");
    }
    

    var that = this;

    fetch("/product/edit-metafield", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(res => {
      let index = rowData[4];
      that.querySelector("tbody tr.index-" + index + "").classList.add("done");
    });
  }
  // reformatData(rowData) {

  //   let rowMeta = []
  //   for(let i = 3; i < this.displayColumns.length; i++) {
  //     let metafield = {key: this.displayColumns[i].key, value: rowData[i - 1]};
  //     rowMeta.push(metafield);
  //   }
  //   return rowMeta;
  // }
  countDone(){
    this.doneCount++;

    let percentDone = parseFloat(this.doneCount * 100) / this.totalPages;
    
    this.progressCount.innerText = this.doneCount + "/" + this.totalPages;
    this.progressBar.ariaValueNow = this.doneCount;
    this.progressBar.style.width = percentDone + "%";
  }
  
  slugify(str){
    return str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '_')
      .replace(/^-+|-+$/g, '');
  }
}

customElements.define("product-bulk-mtfield", ProductBulkMTField);