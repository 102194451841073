import { forEach } from "lodash";
/**
 * Nhiệm vụ: thực hiện các thao tác liên quan đến table
 * 1. generate table html
 * 2. hiển thị các row chạy đúng và sai hay warning
 * 3. các hành động liên quan từ nút Toggle
 * 
 */


class TableHandle2 {
  /** 
   * type => type of object
   * tableWrapper => parent element of table
   * data => data get from file CSV
   * status => status want add to table after generated (add class to table)
   * displayColumns(array) => index of columns will be display after generated (column index base in CSV file)
   * Ex: displayColumns = [
   *      { key: 'title', label: "Title" },
   *      { key: 0, label: "Handle" },
   *     ]
   */
  constructor(type, tableWrapper, data, resource='', displayColumns = []){
    
    this.type = type;
    this.tableWrapper = tableWrapper;
    this.data = data;
    this.resource = resource;
    this.displayColumns = displayColumns;
    this.rowStatuses = ["success", "error", "warning"];
  }
  
  setData(data){
    this.data = data;
  }

  generateTable(){
    if(this.tableWrapper.getElementsByTagName("table").length > 0){
      this.tableWrapper.getElementsByTagName("table")[0].remove();
    }

    this.createTable();
    this.createTableHeading();
    
    this.createTableBody();
  }
  
  createTable(){
    this.table = document.createElement("table");
    this.tableHead = document.createElement("thead");
    this.tableBody = document.createElement("tbody");
    this.tableFoot = document.createElement("tfoot");

    this.table.classList.add("table", "table-striped", "table-hover");
    
    this.table.append(this.tableHead, this.tableFoot, this.tableBody);
    this.tableWrapper.append(this.table);

    this.tableWrapper.removeAttribute("hidden");
    if(this.resource == '') {
      this.resource = "view-" + this.type;
    }
    this.table.classList.add(this.resource);
  }

  createTableHeading(){
    let data = this.data[0];
    let tableHeadRow = document.createElement("tr");
    let tableFootRow = document.createElement("tr");
    
    let headToggleAll = document.createElement('th');
    let toggleAll = document.createElement("input");
    toggleAll.setAttribute("type", "checkbox");
    toggleAll.setAttribute("name", "toggle-all");
    toggleAll.classList.add("toggle-all");
    
    toggleAll.addEventListener("change", this.onToggleAll.bind(this));
    headToggleAll.append(toggleAll);
    
    let footToggleAll = headToggleAll.cloneNode(true);
    footToggleAll.childNodes[0].addEventListener("change", this.onToggleAll.bind(this));
    
    tableFootRow.append(headToggleAll);
    tableHeadRow.append(footToggleAll);

    for(var i in this.displayColumns){
      let colSetting = this.displayColumns[i];
      let colHead = document.createElement("th");
      colHead.innerText = colSetting.label;
      tableFootRow.append(colHead.cloneNode(true));
      tableHeadRow.append(colHead);
    }

    this.tableHead.append(tableHeadRow);
    this.tableFoot.append(tableFootRow);
  }

  createToggleCell(i){
    let cellToggle = document.createElement("td");
    let toggle = document.createElement("input");
      
    toggle.setAttribute("type", "checkbox");
    toggle.setAttribute("name", "toggle");
    toggle.classList.add("toggle");
    toggle.setAttribute("value", "index-" + i);
    cellToggle.append(toggle);

    return cellToggle;
  }
  /**
   * 
   * @param {string} text | Text inside column
   * @param {object} settings | Setting from displayColumns
   * @returns 
   */
  createCell(text, settings){
    let cell = document.createElement("td");
    cell.innerText = text;
    cell.classList.add('cell', settings.key);
    cell.title = settings.label;

    return cell;
  }
  /**
   * 
   * @param {string} text | Text inside column
   * @param {object} settings | Setting from displayColumns
   * @returns 
   */
  createIndexCell(index, settings){
    let cell = document.createElement("td");
    cell.innerText = index;
    cell.classList.add('cell', settings.key);
    cell.title = settings.label;

    return cell;
  }

  createTableBody(){
    let content = this.data;
    let headingHandle = [];

    if(content.length < 1) {
      return;
    }

    for(let k = 0; k < content[0].length; k++){
      headingHandle.push(content[0][k])
    }

    for(let i = 1; i <= content.length; i++){
      
      let row = document.createElement("tr");

      let data = [];

      if(this.resource == 'shopify') {
        data = content[i - 1];
      }else {
        data = content[i];
      }
      
      row.classList.add("index-" + i);
      
      row.append(this.createToggleCell(i));

      for(let j = 0; j < this.displayColumns.length; j++){
        let setting = this.displayColumns[j];

        if(this.resource == 'shopify') {
          switch(setting.key){
            case 'index':
              row.append(this.createIndexCell(i, setting));
              break;
            default:
              row.append(this.createCell(data[setting.key], setting));
              break;
          }
        }else {
          switch(setting.key){
            case 'index':
              row.append(this.createIndexCell(i, setting));
              break;
            default:
              row.append(this.createCell(data[j - 1], setting));
              break;
          }
        }
        
      }

      this.tableBody.append(row);
    }
  }
  onToggleAll(e){
    let allToggler = this.tableWrapper.getElementsByClassName("toggle");
    let allAllToggler = this.tableWrapper.getElementsByClassName("toggle-all");
    let isChecked = e.currentTarget.checked;

    for(let i = 0; i < allToggler.length; i++){
      allToggler[i].checked = isChecked;
    }
    for(let i = 0; i < allAllToggler.length; i++){
      allAllToggler[i].checked = isChecked;
    }
  }

  setRowStatus(index, status){
    let row = this.tableBody.getElementsByClassName("index-" + index)[0];

    this.rowStatuses.forEach(e => row.classList.remove('bg-' + e));
    
    row.classList.add('bg-' + status);
  }
}
export default TableHandle2;