class CreatePage extends HTMLElement{
  
  constructor(){
    super();
    this.selector = this.getElementsByClassName('create_page_btn')[0];
    this.form = this.getElementsByTagName("form")[0];
    this.token = document.getElementsByClassName('create_page_token')[0].value;

    this.seoTitleKey = "title_tag";
    this.seoDescKey = "description_tag";

    this.message = this.getElementsByClassName('message')[0];

    this.selector.addEventListener("click", this.createPage.bind(this));
  }
  init() {
    this.title = document.getElementsByClassName('create_page_title')[0].value;
    this.content = document.getElementsByClassName('create_page_content')[0].value;
    this.seoTitle = document.getElementsByClassName('create_page_seo-title')[0].value;
    this.seoHandle = document.getElementsByClassName('create_page_seo-handle')[0].value;
    this.seoDesc = document.getElementsByClassName('create_page_seo-desc')[0].value;
    this.template = document.getElementsByClassName('create_page_template_suffix')[0].value;
  }

  createPage(e) {
    e.preventDefault();
    this.init();
  
    let formData = new FormData();
    formData.append("title", this.title);
    formData.append("body_content", this.content);
    formData.append("seo_handle", this.seoHandle);
    formData.append("template_suffix", this.template);
    formData.append("_token", this.token);
  
    fetch("/pages/create", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      let page_id = result.page.id;
      let status = result.status;
      let message = result.message;
      let message_color = '#ff0000';
      if(page_id != undefined && status == "OK") {
        setTimeout(this.addSEOMeta(page_id, this.seoTitleKey, this.seoTitle), 100);
        setTimeout(this.addSEOMeta(page_id, this.seoDescKey, this.seoDesc), 200);
        message = "create page successfully !";
        message_color = 'rgb(2, 222, 64)';
      } 

      this.message.style.color = message_color;
        
      this.message.innerText = message;
      this.message.style.display = "block";
      console.log(result);
    });
  }

  addSEOMeta(pageID, key, value){
    let formData = new FormData();
    formData.append("id", pageID);
    formData.append("key", key);
    formData.append("value", value);
    formData.append("_token", this.token);
    formData.append("action", "add_seo_title");

    fetch("/pages/add-seo", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      console.log(result);
    });
  }
  
}
customElements.define("create-page", CreatePage);