import { rule } from "postcss";

class GeoIPFormElement extends HTMLFormElement{
  connectedCallback(){

    this.token = document.head.querySelector('meta[name="csrf-token"]').content;
    
    this.changeStatusAction = this.querySelector("#change-status-url").value;

    this.actionName = "add";

    this.addEventListener("submit", this.onSubmitCallBack.bind(this) );
    this.addEventListener("reset", this.reset.bind(this) );
  }

  get isUpdate(){
    return this.querySelector("#rule-id").value != 0;
  }

  get formData(){
    let id = this.querySelector("#rule-id").value;
    let title = this.querySelector("#title").value;
    let countries = this.querySelector("#countries").value;
    let redirect_to = this.querySelector("#redirect_to").value;
    let message = this.querySelector("#message").value;
    let preserve_relative = this.querySelector("#preserve_relative").checked;
    let formData = new FormData();

    formData.append("id", id);
    formData.append("title", title);
    formData.append("countries", countries);
    formData.append("redirect_to", redirect_to);
    formData.append("message", message);
    formData.append("preserve_relative", preserve_relative);

    formData.append("_token", this.token);

    return formData;
  }

  onSubmitCallBack(e){
    e.preventDefault();

    if (! this.checkValidity()) {
      this.classList.add('was-validated')
      
      return false;
    }

    this.saveRule();

    return false;
  }

  saveRule(){
    let that = this;

    fetch(this.action, { method: 'post', body: this.formData})
      .then(res => res.json())
      .then((res) => {
        if(that.isUpdate)
          document.querySelector("#geoip-list").updateRule(res.data);
        else
          document.querySelector("#geoip-list").addRule(res.data);
        
        that.reset();
      })
      .catch(e => console.error(e));
  }

  reset(){
    super.reset();

    this.querySelector("#rule-id").value = "";
  }

  fill(rule){
    this.querySelector("#rule-id").value = rule.id;
    this.querySelector("#title").value = rule.title;
    this.querySelector("#countries").value = rule.countries;
    this.querySelector("#redirect_to").value = rule.redirect_to;
    this.querySelector("#message").value = rule.message;

    this.querySelector("#preserve_relative").checked = rule.preserve_relative;
  }

  /**
   * 
   * @param {String} action "add" or "update"
   */
  switchAction(action){
    if(action == "add"){
      this.querySelector("#action-name").value = "add";
      this.actionName = "add";
    }
    else if(action == "update"){
      this.querySelector("#action-name").value = "update";
      this.actionName = "update";
    }
  }
}

customElements.define("geoip-form", GeoIPFormElement, {extends: "form"});

window.geoIPForm = document.querySelector("form[is=geoip-form]");