import { forEach } from "lodash";
/**
 * Nhiệm vụ: thực hiện các thao tác liên quan đến table
 * 1. generate table html
 * 2. hiển thị các row chạy đúng và sai hay warning
 * 3. các hành động liên quan từ nút Toggle
 * 
 */
class TableHandle {
  /** 
   * type => type of object
   * tableWrapper => parent element of table
   * data => data get from file CSV
   * status => status want add to table after generated (add class to table)
   * displayColumns(array) => index of columns will be display after generated (column index base in CSV file)
   */
  constructor(type, tableWrapper, data, status = '', displayColumns = []){
    this.type = type;
    this.tableWrapper = tableWrapper;
    this.data = data;
    this.status = status;
    this.displayColumns = displayColumns;
    this.rowStatuses = ["success", "error", "warning"];
  }
  
  setData(data){
    this.data = data;
  }

  generateTable(){
    if(this.tableWrapper.getElementsByTagName("table").length > 0){
      this.tableWrapper.getElementsByTagName("table")[0].remove();
    }

    this.createTable();
    this.createTableHeading();
    
    this.createTableBody();
  }
  
  createTable(){
    this.table = document.createElement("table");
    this.tableHead = document.createElement("thead");
    this.tableBody = document.createElement("tbody");
    this.tableFoot = document.createElement("tfoot");

    this.table.classList.add("table", "table-striped", "table-hover");
    
    this.table.append(this.tableHead, this.tableFoot, this.tableBody);
    this.tableWrapper.append(this.table);

    this.tableWrapper.removeAttribute("hidden");
    if(this.status == '') {
      this.status = "view-" + this.type;
    }
    this.table.classList.add(this.status);
  }

  createTableHeading(){
    let data = this.data[0];
    let tableHeadRow = document.createElement("tr");
    let tableFootRow = document.createElement("tr");
    
    let headToggleAll = document.createElement('th');
    let toggleAll = document.createElement("input");
    toggleAll.setAttribute("type", "checkbox");
    toggleAll.setAttribute("name", "toggle-all");
    toggleAll.classList.add("toggle-all");
    
    toggleAll.addEventListener("change", this.onToggleAll.bind(this));
    headToggleAll.append(toggleAll);
    
    let footToggleAll = headToggleAll.cloneNode(true);
    footToggleAll.childNodes[0].addEventListener("change", this.onToggleAll.bind(this));
    
    tableFootRow.append(headToggleAll);
    tableHeadRow.append(footToggleAll);


    for(let j = 0; j < data.length; j++){
      if(this.displayColumns.length > 0) {
        if(this.displayColumns.includes(j)) {
          let colHead = document.createElement("th");
          colHead.innerText = data[j];
          tableFootRow.append(colHead.cloneNode(true));
          tableHeadRow.append(colHead);
        }
      }else{
        let colHead = document.createElement("th");
        colHead.innerText = data[j];
        if(this.status == "view-tags" && j == data.length - 1){
          colHead.classList.add(["tags"]);
        }
        else if(j > 1){
          colHead.classList.add("filter");
        }
        tableFootRow.append(colHead.cloneNode(true));
        tableHeadRow.append(colHead);
      }

    }

    this.tableHead.append(tableHeadRow);
    this.tableFoot.append(tableFootRow);
  }

  createTableBody(){
    let content = this.data;
    let headingHandle = [];

    for(let k = 0; k < content[0].length; k++){
      headingHandle.push(content[0][k])
    }

    for(let i = 1; i < content.length; i++){
      
      let row = document.createElement("tr");
      let data = content[i];

      row.classList.add("index-" + i);

      if(data[0].trim()){
        row.classList.add(this.type + "-" + data[0].trim());
      }
      else if(data[1].trim()){
        row.classList.add(this.type + "-" + data[1].trim());
      }
      else{
        console.error(this.type + " at index " + ( i + 1 ) + " does not contains " + this.type + " ID or handle, please specify it!");
      }

      let cellToggle = document.createElement("td");
      let toggle = document.createElement("input");
      
      toggle.setAttribute("type", "checkbox");
      toggle.setAttribute("name", "toggle");
      toggle.classList.add("toggle");
      toggle.setAttribute("value", "index-" + i);
      cellToggle.append(toggle);
      row.append(cellToggle);

      
      for(let j = 0; j < data.length; j++){
        if(this.displayColumns.length > 0) {
          if(this.displayColumns.includes(j)) {
            let cell = document.createElement("td");
            cell.innerText = data[j];
            row.append(cell);
          }
        }else{
          let cell = document.createElement("td");

          if(this.status == "view-tags" && j == data.length - 1){
            cell.classList.add(["tags"]);
          }
          else if(j > 1){
            cell.classList.add("filter");
          }

          cell.innerText = data[j];
    
          row.append(cell);
        }
        
      }

      this.tableBody.append(row);
    }
  }
  onToggleAll(e){
    let allToggler = this.tableWrapper.getElementsByClassName("toggle");
    let allAllToggler = this.tableWrapper.getElementsByClassName("toggle-all");
    let isChecked = e.currentTarget.checked;

    for(let i = 0; i < allToggler.length; i++){
      allToggler[i].checked = isChecked;
    }
    for(let i = 0; i < allAllToggler.length; i++){
      allAllToggler[i].checked = isChecked;
    }
  }

  setRowStatus(index, status){
    let row = this.tableBody.getElementsByClassName("index-" + index)[0];

    this.rowStatuses.forEach(e => row.classList.remove('bg-' + e));

    row.classList.add('bg-' + status);
  }
}
export default TableHandle;