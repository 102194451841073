(function () {
  'use strict'

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})()

class PreviewESForm extends HTMLElement{
  constructor(){
    super();
    this.btnSaveEntries = document.getElementById('btnSaveFormEntries');
    this.btnSaveEntries.addEventListener('click', this.saveEntrydata.bind(this));
  }
  saveEntrydata(e){
    e.preventDefault();
    let form = this.querySelector('.esform-preview');
    
    let curentformData = new FormData(form);
    let sendformData = new FormData();
    let action = form.action;
    
    let checkValidate = [];
    form.querySelectorAll('.field-required').forEach((elem) => {
                
      if(elem.value.length == 0){
        checkValidate.push('false');
      }
    });

    form.querySelectorAll('.field-checkbox-required').forEach((elem) => {
        let valueCheckbox = [];
        elem.querySelectorAll('input[type="checkbox"]').forEach( (checkbox) => {
            if(checkbox.checked){
              valueCheckbox.push(checkbox.value);
            }
        });
        console.log(valueCheckbox);
        if(valueCheckbox.length == 0){
            checkValidate.push('false');
            elem.querySelectorAll('input[type="checkbox"]').forEach( (checkbox) => {
              checkbox.setAttribute('required', true);
            });
            elem.querySelector('.invalid-feedback').classList.add('show');
        } else {
          elem.querySelectorAll('input[type="checkbox"]').forEach( (checkbox) => {
            checkbox.removeAttribute('required');
          });
          elem.querySelector('.invalid-feedback').classList.remove('show');
        }
    });
    form.querySelectorAll('.field-radio-required').forEach((elem) => {
        let valueCheckbox = [];
        elem.querySelectorAll('input[type="radio"]').forEach( (radio) => {
            if(radio.checked){
                valueCheckbox.push(radio.value);
            }
        });
        if(valueCheckbox.length == 0){
            checkValidate.push('false');
            elem.querySelectorAll('input[type="radio"]').forEach( (radio) => {
              radio.setAttribute('required', true);
            });
            elem.querySelector('.invalid-feedback').classList.add('show');
        } else {
          elem.querySelectorAll('input[type="radio"]').forEach( (radio) => {
            radio.removeAttribute('required');
          });
          elem.querySelector('.invalid-feedback').classList.remove('show');
        }
    });
    
    if(checkValidate.includes('false')){
      e.preventDefault();
      e.stopPropagation();
      form.classList.add('was-validated');
      return false;
    }
    
    
    let checkedValue = {};
    let a = form.querySelectorAll('input[type="checkbox"]:checked');

    for (let i = 0, len = a.length; i < len; i++) {
      let id = a[i].getAttribute("name");
      if (checkedValue.hasOwnProperty(id)) {
        checkedValue[id] += ';'+a[i].value;
      } else
      checkedValue[id] = a[i].value;
    }
    for (const [key, value] of Object.entries(checkedValue)) {
      curentformData.append(key, value);
    }
    
    const formDataObj = {};
    curentformData.forEach((value, key) => (formDataObj[key] = value));
    
    sendformData.append('store', curentformData.get('store'));
    sendformData.append('entry_data', JSON.stringify(formDataObj));
    sendformData.append('ip', curentformData.get('ip'));
    
    var that = this;
    fetch(action, {
      method: "POST",
      body: sendformData
    })
    .then(res => res.json())
    .then(result => {
      console.log(result['data']);
      let messEle = document.createElement('p');
      if(result['status'] === "OK"){
        messEle.className = 'alert alert-success message';
        this.btnSaveEntries.disabled = true;
      } else {
        messEle.className = 'alert alert-warning message';
        this.btnSaveEntries.disabled = false;
      }
      messEle.append(result['message']);
      this.prepend(messEle);
    });
    
  }
}
customElements.define("esform-preview", PreviewESForm);