
import './bootstrap';
import './global/copy-to-clipboard';
import './global/shopify-store-form';
import './pages/create-page';
import "./products/product-bulktags";
import "./pages/page-pce-import";
import "./pages/page-pce-list";
import "./collections/collection-pce-import";
import "./collections/collection-pce-delete";
import "./products/product-bulk-mtfield";
import "./products/product-edit";
import "./products/products-list-table";
import "./tools/orders/tracking";
import "./tools/youtube/lite-youtube-embed";
import "./geoip/geoip-form";
import "./geoip/geoip-rules-list";
import "./geoip/geoip-toggle-status";
import "./geoip/geoip-rule-item";
import "./esformfield/es-fields-to-form";
import "./esformfield/es-form-field";
import './global/dropdown-autocomplete';
import "./esformfield/es-form-preview";

window.addEventListener('DOMContentLoaded', event => {

  // Toggle the side navigation
  const sidebarToggle = document.body.querySelector('#sidebarToggle');
  if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sb-sidenav-toggled');
      // }
      sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          document.body.classList.toggle('sb-sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      });
  }

});
