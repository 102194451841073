import FileHandle from '../global/file-handle';
import TableHandle from "../global/table-handle-2";
class CollectionPCEImportBulkTags extends HTMLElement {
  constructor(){
    super();
    this.type = "collection";
    
    this.tableWrapper = this.getElementsByClassName("table-wrapper")[0];
    this.progress = this.getElementsByClassName("progress")[0];
    this.progressBar = this.getElementsByClassName("progress-bar")[0];
    this.progressCount = this.getElementsByClassName("progress-count")[0];

    this.displayColumns = [
      {key: 'index', label: "Index"},
      {key: 'Handle', label: "Handle"},
      {key: 'Title', label: "Title"},
      // {key: 'BodyHTML', label: "Short Content"},
      // {key: 'TemplateSuffix', label: "Template"},
      // {key: 'Metafield:title_tag', label: "SEO Title"},
      // {key: 'Metafield:description_tag', label: "SEO Description"},
      // {key: 'Column', label: "Column"},
      // {key: 'Relation', label: "Relation"},
      // {key: 'Condition', label: "Condition"},
    ];
    
    this.token = document.head.querySelector('meta[name="csrf-token"]').content;

    this.btnImport = this.getElementsByClassName("btn-start-import")[0];
    
    this.btnImport.addEventListener("click", this.startImportCollectionsPCE.bind(this));
    this.isLoadFileContent = true;

    this.fileHandle = new FileHandle(this);

    this.tableHandle = new TableHandle(this.type, this.tableWrapper, null, "", this.displayColumns);
    
    this.seoTitleKey = "title_tag";
    this.seoDescKey = "description_tag";
    this.countCollectionsImport = 0;

    this.addEventListener("prm:fileLoaded", this.onFileLoad.bind(this) );
  }

  onFileLoad(e){
    /* code on file load here */
    let data = e.detail.data;
    console.log(data);
    this.tableHandle.setData(data);
    this.tableHandle.generateTable();
  }
  
  startImportCollectionsPCE() {
    let toggle = this.getElementsByClassName("toggle");
    let indexHasChoose = [];
    for(let i = 0; i < toggle.length; i++) {
      if(toggle[i].checked) {
        let toggleValue = toggle[i].value;
        toggleValue = toggleValue.replace("index-","");
        indexHasChoose.push(parseFloat(toggleValue));
      }
    }

    if(indexHasChoose.length == 0) return;
    
    let data = this.fileHandle.getData();
    
    this.collectionsImport = [];

    for(let j in indexHasChoose) {
      let collectionData = data[indexHasChoose[j]];
      collectionData['index'] = indexHasChoose[j];
      this.collectionsImport.push(collectionData);
    }
    
    this.importInterval = null;
    this.totalCollections = this.collectionsImport.length;
    this.startedCount = 0;
    this.doneCount = 0;
    
    this.progressBar.ariaValueMax = this.totalCollections;
    this.progressCount.innerText = "0/" + this.totalCollections;
    // console.log(this.progressBar.ariaValueMax);
    this.progress.classList.remove("hide");

    this.importInterval = setInterval(this.importCollectionsToShopify.bind(this), 2200);
  }
    
  importCollectionsToShopify() {
    var content = this.collectionsImport.shift();
    this.startedCount++;
    
    this.checkCollectionExistByHandle(content);
    
    if (this.collectionsImport.length == 0) {
      clearInterval(this.importInterval);
      return;
    }
  }

  checkCollectionExistByHandle(content){
    let handle = content[0];
    let formData = new FormData();

    formData.append("_token", this.token);
    formData.append("handle", handle);
    
    var seoTitle = content[1];
    // var seoTitle = content[4];
    // var seoDesc = content[5];

    var that = this;
    fetch("/collection/info", {method: "POST", body: formData})
        .then(res => res.json())
        .then( (res) => {
          let collection_id = res.id;
          
          if(collection_id != undefined) {
            setTimeout(that.addSEOMeta(collection_id, that.seoTitleKey, seoTitle), 1500);
            // setTimeout(that.addSEOMeta(collection_id, that.seoDescKey, seoDesc), 1500);
            console.log("Collection: " + content[1] + " is exist, added SEO meta");
          } else {
            // that.createCollection(content);
          }
          that.countDone();
        })
        
  }

  createCollection(content) {
    let handle = content[0];
    let title = content[1];
    let body = content[2];
    let template = content[3];
    
    let seoTitle = content[4];
    let seoDesc = content[5];

    let column = content[6];
    let relation = content[7];
    let conditions = content[8];

    let formData = new FormData();
    formData.append("title", title);
    formData.append("body_content", body);
    formData.append("template_suffix", template);
    formData.append("seo_handle", handle);
    formData.append("column", column);
    formData.append("relation", relation);
    formData.append("conditions", conditions);
    formData.append("_token", this.token);

    var that = this;

    fetch("/collections/create", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      console.log(result);
      let collection_id = result.collection.id;
      if(collection_id != '') {

        console.log('collection: ' + content[1] + ' does not exist, create collection and add SEO meta');
        setTimeout(that.addSEOMeta(collection_id, that.seoTitleKey, seoTitle), 500);
        setTimeout(that.addSEOMeta(collection_id, that.seoDescKey, seoDesc), 1500);

        this.tableHandle.setRowStatus(content.index, 'success');
        
      }
      this.countCollectionsImport += 1;

    });
  }

  addSEOMeta(collectionID, key, value){
    let formData = new FormData();
    formData.append("id", collectionID);
    formData.append("key", key);
    formData.append("value", value);
    formData.append("_token", this.token);
    formData.append("action", "add_seo_title");

    var that = this;

    fetch("/collections/add-seo", {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      console.log(result);
    });
  }

  countDone(){
    this.doneCount++;

    let percentDone = parseFloat(this.doneCount * 100) / this.totalCollections;
    
    this.progressCount.innerText = this.doneCount + "/" + this.totalCollections;
    this.progressBar.ariaValueNow = this.doneCount;
    this.progressBar.style.width = percentDone + "%";

    if (this.doneCount == this.totalCollections) {
      setTimeout(() => {
        this.progressBar.style.backgroundColor = "rgb(2, 222, 64)";
        this.progressCount.innerText = "DONE";
      }, 500);
      
    }
  }
  
}
customElements.define("collection-pce-import", CollectionPCEImportBulkTags);