class TrackingFinder extends HTMLElement{
  constructor(){
    super();

    this.orderID = 0;
    this.finder = this.getElementsByClassName("finder")[0];
    this.orderIDInputer = this.getElementsByClassName("orderid")[0];
    this.trackingNumber = this.getElementsByClassName("tracking-number")[0];
    this.trackingCompany = this.getElementsByClassName("tracking-company")[0];
    this.store = this.getElementsByClassName("store")[0].value;
    this.token = document.head.querySelector('meta[name="csrf-token"]').content;

    this.orderIDInputer.addEventListener("change", this.onEnterOrderID.bind(this));
    this.finder.addEventListener("click", this.findTracking.bind(this));
  }

  onEnterOrderID(e){
    let value = e.currentTarget.value.toUpperCase();
    if( ! value.startsWith("#") ){
      value = "#" + value;
    }

    this.orderID = value;
  }

  startFinding(){
    this.classList.add("loading");
    if(this.classList.contains("done"))
      this.classList.remove("done");
  }

  endFinding(){
    this.classList.remove("loading");
    this.classList.add("done");
  }

  findTracking(e){
    e.preventDefault();
    this.startFinding();
    console.log("finding tracking number on Order: " + this.orderID);

    let data = new FormData();
    data.append("orderid", this.orderID);
    data.append("store", this.store);
    data.append("_token", this.token);

    fetch("/services/trackingfinder", {
      method: "POST",
      body: data
    })
    .then(res => res.json())
    .then(res => { this.showResponse(res); this.endFinding() })
    .catch(e => { this.endFinding(); console.error(e) });
    return false;
  }

  showResponse(res){
    if(res.number){

      if(res.url){
        let numberElement = document.createElement("a");
        numberElement.href = res.url;
        numberElement.innerText = res.number;
        numberElement.target = "_blank";

        this.trackingNumber.innerHTML = numberElement.outerHTML;
      }
      else{
        this.trackingNumber.innerText = res.number;
      }

      this.trackingCompany.innerText = res.company;
    }
  }
}

customElements.define("tracking-finder", TrackingFinder);