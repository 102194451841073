
import FileHandle from '../global/file-handle';
import TableHandle from "../global/table-handle-2";
class CollectionPCEDelete extends HTMLElement {
  constructor(){
    super();
    this.type = "collection";
    
    this.tableWrapper = this.getElementsByClassName("table-wrapper")[0];
    this.progress = this.getElementsByClassName("progress")[0];
    this.progressBar = this.getElementsByClassName("progress-bar")[0];
    this.progressCount = this.getElementsByClassName("progress-count")[0];

    this.displayColumns = [
      {key: 'index', label: "Index"},
      {key: 'Handle', label: "Handle"},
      {key: 'Title', label: "Title"},
      {key: 'BodyHTML', label: "Short Content"},
      {key: 'TemplateSuffix', label: "Template"},
      {key: 'Metafield:title_tag', label: "SEO Title"},
      {key: 'Metafield:description_tag', label: "SEO Description"},
      {key: 'Column', label: "Column"},
      {key: 'Relation', label: "Relation"},
      {key: 'Condition', label: "Condition"},
    ];
    
    this.token = document.head.querySelector('meta[name="csrf-token"]').content;

    this.btnDelete = this.getElementsByClassName("btn-start-delete")[0];
    
    this.btnDelete.addEventListener("click", this.startDeleteCollectionsPCE.bind(this));
    
    this.isLoadFileContent = true;
    this.fileHandle = new FileHandle(this);

    this.tableHandle = new TableHandle(this.type, this.tableWrapper, null, "", this.displayColumns);
    
    this.seoTitleKey = "title_tag";
    this.seoDescKey = "description_tag";
    this.countCollectionsDelete = 0;

    this.addEventListener("prm:fileLoaded", this.onFileLoad.bind(this) );
  }

  onFileLoad(e){
    /* code on file load here */
    let data = e.detail.data;
    console.log(data);
    this.tableHandle.setData(data);
    this.tableHandle.generateTable();
  }
  
  startDeleteCollectionsPCE() {
    let toggle = this.getElementsByClassName("toggle");
    let indexHasChoose = [];
    for(let i = 0; i < toggle.length; i++) {
      if(toggle[i].checked) {
        let toggleValue = toggle[i].value;
        toggleValue = toggleValue.replace("index-","");
        indexHasChoose.push(parseFloat(toggleValue));
      }
    }

    if(indexHasChoose.length == 0) return;
    
    let data = this.fileHandle.getData();
    
    this.collectionsDelete = [];

    for(let j in indexHasChoose) {
      let collectionData = data[indexHasChoose[j]];
      collectionData['index'] = indexHasChoose[j];
      this.collectionsDelete.push(collectionData);
    }
    
    this.deleteInterval = null;
    this.totalCollections = this.collectionsDelete.length;
    this.startedCount = 0;
    this.doneCount = 0;
    
    this.progressBar.ariaValueMax = this.totalCollections;
    this.progressCount.innerText = "0/" + this.totalCollections;
    // console.log(this.progressBar.ariaValueMax);
    this.progress.classList.remove("hide");

    this.deleteInterval = setInterval(this.deleteCollectionsFromShopify.bind(this), 2200);
  }
    
  deleteCollectionsFromShopify() {
    var content = this.collectionsDelete.shift();
    this.startedCount++;
    
    // console.log(content);

    
    this.checkCollectionExistByHandle(content);
    
    if (this.collectionsDelete.length == 0) {
      clearInterval(this.deleteInterval);
      return;
    }
  }

 

  checkCollectionExistByHandle(content){
    let handle = content[0];
    let formData = new FormData();

    formData.append("_token", this.token);
    formData.append("handle", handle);

    var that = this;
    fetch("/collection/info", {method: "POST", body: formData})
        .then(res => res.json())
        .then( (res) => {
          let collection_id = res.id;
          
          if(collection_id != undefined) {
            that.deleteCollection(collection_id, content.index);
            console.log("Collection: " + content[1] + " is exist, send request to delete");
          }
          that.countDone();
        })
        
  }
  // checkCollectionExistByHandle(content){
  //   let handle = content[0];
  //   let formData = new FormData();

  //   formData.append("_token", this.token);
  //   formData.append("handle", handle);

  //   var that = this;
  //   fetch("/page/info", {method: "POST", body: formData})
  //       .then(res => res.json())
  //       .then( (res) => {
  //         let collection_id = res.id;
          
  //         if(collection_id != undefined) {
  //           that.deleteCollection(collection_id, content.index);
  //           console.log("page: " + content[1] + " is exist, send request to delete");
  //         }else{
  //           console.log("page: " + content[1] + " does not exist");
  //         }
  //         that.countDone();
  //       })
        
  // }

  deleteCollection(id, index){
    let formData = new FormData();
    
    formData.append("_token", this.token);

    formData.append("id", id);

    fetch("/collections/delete/" + id, {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      
      console.log(result);
      this.tableHandle.setRowStatus(index, 'success');
      this.countCollectionsDelete += 1;
      this.countDone();
    });
  }

  countDone(){
    

    let percentDone = parseFloat(this.countCollectionsDelete * 100) / this.totalCollections;
    
    this.progressCount.innerText = this.countCollectionsDelete + "/" + this.totalCollections;
    this.progressBar.ariaValueNow = this.countCollectionsDelete;
    this.progressBar.style.width = percentDone + "%";

    if (this.countCollectionsDelete == this.totalCollections) {
      setTimeout(() => {
        this.progressBar.style.backgroundColor = "rgb(2, 222, 64)";
        this.progressCount.innerText = "DONE";
      }, 500);
      
    }
  }
  
}
customElements.define("collection-pce-delete", CollectionPCEDelete);