import TableHandle from "../global/table-handle-2";
import Papa from "papaparse";
import { result } from "lodash";
class ListTable extends HTMLElement{
  constructor(){
    super();
    
    if(this.dataset.tabledata != null) {
      this.data = JSON.parse(this.dataset.tabledata);
    }
    
    
    this.type = this.dataset.type;
    this.resource = this.dataset.resource;
    this.tableWrapper = this.getElementsByClassName("table-wrapper")[0];
    this.progress = this.getElementsByClassName("progress")[0];
    this.progressBar = this.getElementsByClassName("progress-bar")[0];
    this.progressCount = this.getElementsByClassName("progress-count")[0];
    this.countRowsDelete = 0;
    
    this.displayColumns = [];
    this.exportColumns = [];
    this.token = document.head.querySelector('meta[name="csrf-token"]').content;
    
  }
  
  init(event = ''){
    if(event != '') {
      this.data = event.detail.data;
    }
    let data = this.data;
    this.tableHandle = new TableHandle(this.type, this.tableWrapper, data, this.resource, this.displayColumns);
    this.tableHandle.generateTable();
  }
  actionHandle(e) {
    e.preventDefault();
    let action = this.actions.value;
    
    let reminder_text = this.reminder.innerText;

    reminder_text.replace("type", this.type + "s");
    reminder_text.replace("action", action);
    this.reminder.innerText = reminder_text;
    console.log(action);
    if(action == 'delete') {
      this.startDeleteRows();
      
    }else if(action == 'export') {
      this.startExport();
    }
  }
  searchAction() {
    let keyword = this.getElementsByClassName("keyword")[0].value;
    let data = this.data;
    let results = [];
    for(let i = 0; i < data.length; i++) {
      let title = data[i]['title'].toLowerCase();
      if(title.includes(keyword.toLowerCase())) {
        results.push(data[i]);
      }
    }
    if(keyword = '') {
      results = this.data;
    }
    this.init(results);
    
  }
  getRows() {
    let toggle = this.getElementsByClassName("toggle");
    this.indexHasChoose = [];
    for(let i = 0; i < toggle.length; i++) {
      if(toggle[i].checked) {
        let toggleValue = toggle[i].value;
        toggleValue = toggleValue.replace("index-","");
        this.indexHasChoose.push(parseFloat(toggleValue));
      }
    }
  }

  getDataExport() {
    this.getRows();
    if(this.indexHasChoose.lengt == 0) {
      this.reminder.classList.remove("hide");
      return;
    }
    let dataExport = [];
    for(let j in this.indexHasChoose) {
        dataExport.push(this.data[this.indexHasChoose[j]]);
    }
    return dataExport;
  }
  startExport(e) {
    
    let dataExport = this.getDataExport();
    // console.log(productsExport[0]['id']);
    console.log(dataExport);
    let data = [];
    let exportLabels = [];
    for(let i = 0; i < dataExport.length; i++) {
        let row = [];
        
        for(let j = 0; j < this.exportColumns.length; j++) {
          let colSetting = this.exportColumns[j];
          
          row.push(dataExport[i][colSetting.key]);
          if(i == 0) {
            exportLabels.push(colSetting.label);
          }
        }
        data.push(row);
    }

    if(data.length > 0) {
        var csv = Papa.unparse({
            "fields": exportLabels,
            "data": data
        });
        if(!this.reminder.classList.contains("hide")){
          this.reminder.classList.add("hide");
        }
    }else{
        if(this.reminder.classList.contains("hide")){
            this.reminder.classList.remove("hide");
        }
        if(!this.btnDownload.classList.contains("hide")) {
            this.btnDownload.classList.add("hide");
        }
        return false;
    }
    let date = Date.now();
    let filename = this.type + "s-export-" + date + ".csv";
    this.createDownloadLink(this.btnDownload, csv, filename);
    // this.btnDownload.classList.remove("hide");        
  }
  createDownloadLink(anchorSelector, content, fileName){    
    const blob = new Blob([content], {type: "data:text/plain"}) // Create a blob (file-like object)
    const url = URL.createObjectURL(blob) // Create an object URL from blob
    anchorSelector.setAttribute('href', url) // Set "a" element link
    anchorSelector.setAttribute('download', fileName) // Set download filename
    anchorSelector.click();
  }
  startDeleteRows() {
    this.getRows();
    if(this.indexHasChoose.length == 0) {
      this.reminder.classList.remove("hide");
      return;
    } 
    
    let data = this.data;
    console.log(data);
    this.rowsDelete = [];

    for(let j in this.indexHasChoose) {
      let itemData = data[this.indexHasChoose[j]];
      itemData['index'] = this.indexHasChoose[j];
      this.rowsDelete.push(itemData);
    }
    
    this.deleteInterval = null;
    this.totalRows = this.rowsDelete.length;
    this.startedCount = 0;
    this.doneCount = 0;
    
    this.progressBar.ariaValueMax = this.totalRows;
    this.progressCount.innerText = "0/" + this.totalRows;
    
    this.progress.classList.remove("hide");
    
    this.deleteInterval = setInterval(this.deleteRowsFromShopify.bind(this), 2200);
  }

  deleteRowsFromShopify() {
    var content = this.rowsDelete.shift();
    this.startedCount++;
    
    this.deleteRow(content["id"], content['index']);
    
    if (this.rowsDelete.length == 0) {
      clearInterval(this.deleteInterval);
      return;
    }
  }

  deleteRow(id, index){
    let formData = new FormData();
    
    formData.append("_token", this.token);

    formData.append("id", id);

    fetch("/" + this.type + "s/delete/" + id, {
      method: "POST",
      body: formData
    })
    .then(res => res.json())
    .then(result => {
      
      console.log(result);
      this.tableHandle.setRowStatus(index, 'success');
      
      this.countDone();
    });
  }

  countDone(){
    this.countRowsDelete += 1;

    let percentDone = parseFloat(this.countRowsDelete * 100) / this.totalRows;
    
    this.progressCount.innerText = this.countRowsDelete + "/" + this.totalRows;
    this.progressBar.ariaValueNow = this.countRowsDelete;
    this.progressBar.style.width = percentDone + "%";

    if (this.countRowsDelete == this.totalRows) {
      setTimeout(() => {
        this.progressBar.style.backgroundColor = "rgb(2, 222, 64)";
        this.progressCount.innerText = "DONE";
      }, 500);
      
    }
  }
  initVendorFilter() {
    this.selectVendor = this.getElementsByClassName("filter-vendor")[0];
    this.optionsVendor = [];
    for(let i = 0; i < this.data.length; i++) {
      let vendor = this.data[i]['vendor'];
      if(!this.optionsVendor.includes(vendor)) {
        this.optionsVendor.push(vendor);
      }
    }
    this.optionsVendor.sort();
    let html = '<option value="">Vendor</option>';
    for(let j = 0; j < this.optionsVendor.length; j++) {
      html += '<option value="'+ this.optionsVendor[j] +'">'+ this.optionsVendor[j] +'</option>';
    }
    this.selectVendor.innerHTML = html;
    this.selectVendor.addEventListener("change", this.filterVendor.bind(this));
  }
  filterVendor(e) {
    let vendor = e.target.value;
    let data = [];
    for(let i = 0; i < this.data.length; i++) {
      if(this.data[i]['vendor'] == vendor) {
        data.push(this.data[i]);
      }
    }
    this.init(data);
  }
}
export default ListTable;